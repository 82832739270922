import { useState } from 'react';
import React from 'react';
import './ActiveSimDetail.css';
import {
  EnumAction,
  EnumDisplayConfig,
  EnumSimStatus,
  EventButton,
  NoteType,
  SimType,
} from 'src/api/models';
import { useHistory, useParams } from 'react-router';
import { useEffect } from 'react';
import { useGetSimById, usePostSimError } from 'src/api/simApi';
import useProfile from 'src/hooks/useProfile';
import { useGetNote, usePostNote } from 'src/api/noteApi';
import { useAddPopup, useRemovePopup } from 'src/state/application/hooks';

type NoteByDay = {
  createdAt: string;
  list: NoteType[];
};

const ActiveSimDetail: React.FC = () => {
  //Value
  const history = useHistory();
  const params = useParams<{ simId: string }>();
  const simId = Number(params.simId);
  const profile = useProfile();

  //State
  const [sim, setSim] = useState<SimType>(null);
  const [noteContent, setNoteContent] = useState('');
  const [noteList, setNoteList] = useState(null);

  const [reloadFlag, setReloadFlag] = useState(false);

  //Function
  const getSimById = useGetSimById();
  const postSimError = usePostSimError();
  const getNote = useGetNote();
  const postNote = usePostNote();
  const addPopup = useAddPopup();
  const removePopup = useRemovePopup();

  const onPostNote = () => {
    if (simId) {
      const note: NoteType = {
        functionId: EnumDisplayConfig.Sim_da_ban,
        objectId: simId,
        noteContent: noteContent,
      };
      postNote(note, profile.info.userId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Gửi thành công!',
            },
          });
          setReloadFlag(!reloadFlag);
        })
        .catch((e) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra!',
              message: e.errorMessage,
            },
          });
          setReloadFlag(!reloadFlag);
        });
    }
  };

  //TODO
  const onConfirmReportError = () => {
    const onViewError = () => {
      removePopup('anything', 'context');
      removePopup('anything', 'data');
      const url = `/guide-page`;
      history.push(url);
    };
    const onReportError = () => {
      removePopup('anything', 'context');
      removePopup('anything', 'data');

      postSimError(simId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Báo lỗi thành công!',
            },
          });
          setReloadFlag(!reloadFlag);
          history.goBack();
        })
        .catch((error) => {
          console.log(error);
          setReloadFlag(!reloadFlag);
          history.goBack();
        });
    };
    const listToDo: EventButton[] = [
      {
        name: 'Xem',
        icon: 'fas fa-check',
        actionType: EnumAction.Confirm,
        action: onViewError,
        buttonClass: 'ordered-icon-btn',
        align: 'center',
      },
      {
        name: 'Báo lỗi',
        icon: 'fas fa-times',
        actionType: EnumAction.Cancel,
        action: onReportError,
        buttonClass: 'ordered-icon-btn',
        align: 'center',
      },
    ];
    addPopup({
      confirm: {
        width: 'max(300px, min(calc((100vw - 991px) * 9999), 400px))',
        height: '150px',
        question: 'Bạn muốn xem một số lỗi thường gặp trước khi báo lỗi không?',
        listActionButton: listToDo,
      },
    });
  };

  //Component
  const infoList = () => {
    if (sim)
      return (
        <div className="ordered-input-container">
          <>
            <div className={`ordered-input validate-input`}>
              <div className="ordered-input-title">Tên khách hàng</div>
              <input type="text" value={sim.customerName ? sim.customerName : null} disabled />
            </div>
          </>
          <>
            <div className={`ordered-input validate-input`}>
              <div className="ordered-input-title">Loại sim</div>
              <input
                type="text"
                value={sim?.product?.productName ? sim?.product?.productName : null}
                disabled
              />
            </div>
          </>
          <>
            <div className={`ordered-input validate-input`}>
              <div className="ordered-input-title">Số điện thoại</div>
              <input type="text" value={sim.simPhoneNumber} disabled />
            </div>
          </>
          <>
            <div className={`ordered-input validate-input`}>
              <div className="ordered-input-title">Tiền cọc</div>
              <input type="text" value={sim.deposit} disabled />
            </div>
          </>
          <>
            <div className={`ordered-input validate-input`}>
              <div className="ordered-input-title">Cước hàng tháng</div>
              <input type="text" value={sim.monthlySubscriptionFee} disabled />
            </div>
          </>
          <>
            <div className={`ordered-input validate-input`}>
              <div className="ordered-input-title">Ngày kích hoạt</div>
              <input type="text" value={sim.effectiveDate} disabled />
            </div>
          </>
          <>
            <div
              className={`ordered-input validate-input ${
                sim.status == EnumSimStatus.Actice
                  ? 'blue'
                  : sim.status == EnumSimStatus.Deactive
                  ? 'yellow'
                  : sim.status == EnumSimStatus.Error
                  ? 'red'
                  : sim.status == EnumSimStatus.StopService
                  ? 'lightgray'
                  : null
              }`}
            >
              <div className={`ordered-input-title`}>Trạng thái</div>
              <input
                type="text"
                value={
                  sim.status == EnumSimStatus.Actice
                    ? 'Đang hoạt động'
                    : sim.status == EnumSimStatus.Deactive
                    ? 'Chưa kích hoạt'
                    : sim.status == EnumSimStatus.Error
                    ? 'Lỗi'
                    : sim.status == EnumSimStatus.StopService
                    ? 'Ngừng hoạt động'
                    : null
                }
                disabled
              />
            </div>
          </>
        </div>
      );
  };

  //useEffect
  useEffect(() => {
    if (simId) {
      getSimById(simId)
        .then((data) => {
          setSim(data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setSim(null);
    }
  }, [getSimById, simId, reloadFlag]);

  useEffect(() => {
    if (simId) {
      getNote(EnumDisplayConfig.Sim_da_ban, simId)
        .then((data) => {
          if (data.length > 0) {
            let cr = data[0].createdAt;
            let tempArr: NoteType[] = [];
            const mainArr: NoteByDay[] = [];

            for (let i = 0; i <= data.length; i++) {
              if (i < data.length) {
                if (data[i].createdAt == cr) {
                  tempArr.push(data[i]);
                } else {
                  const temp: NoteByDay = {
                    createdAt: cr,
                    list: tempArr,
                  };
                  mainArr.push(temp);
                  cr = data[i].createdAt;
                  tempArr = [];
                }
              } else {
                const temp: NoteByDay = {
                  createdAt: cr,
                  list: tempArr,
                };
                mainArr.push(temp);
              }
            }

            setNoteList(mainArr);
          }
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra!',
              message: error.errorMessage,
            },
          });
        });
    }
  }, [addPopup, getNote, reloadFlag, simId]);

  //Main
  return (
    <div className="container-fluid ordered-list-container p-0 m-0 ">
      <div className="row p-0 m-0 justify-content-center">
        <div className="col-lg-5 p-1 m-0 order-detail-blank">
          <div className="ordered-list-title">CHI TIẾT SIM</div>
          <div className="ordered-detail-main">
            {infoList()}
            <div className="ordered-list-title">TRAO ĐỔI</div>
            <textarea
              className="note-text-inp"
              value={noteContent}
              onChange={(e) => {
                setNoteContent(e.target.value);
              }}
            ></textarea>
            <div
              className="ordered-btn blue"
              onClick={(e) => {
                onPostNote();
                e.preventDefault();
              }}
            >
              Gửi
            </div>
            {noteList
              ? noteList.map((v) => {
                  return (
                    <div className="note-sim-component">
                      <div className="note-sim-date">
                        <div className="note-sim-line">line</div>
                        <div>{v.createdAt}</div>
                        <div className="note-sim-line">line</div>
                      </div>
                      {v.list.map((item) => {
                        return (
                          <div className="note-sim-item">
                            <div className="note-item-avt">
                              {item.avataUrl ? (
                                <img src={item.avataUrl} alt="avatar" />
                              ) : (
                                <div className="no-sim-avt"></div>
                              )}
                            </div>
                            <div className="note-sim-detail">
                              <div>
                                <b>{item.fullName}</b>
                              </div>
                              <div>{item.noteContent}</div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })
              : null}
            <div className="ordered-btn-container">
              {sim ? (
                sim.status != EnumSimStatus.Error && sim.status != EnumSimStatus.StopService ? (
                  <div
                    className="ordered-btn"
                    onClick={() => {
                      onConfirmReportError();
                    }}
                  >
                    Báo lỗi
                  </div>
                ) : null
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveSimDetail;
