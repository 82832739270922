import { useAddPopup } from 'src/state/application/hooks';
import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useGenOtp } from 'src/api/backend-api';
import useProfile from 'src/hooks/useProfile';
import './ForgotPassword.css';

const ForgotPassword: React.FC = () => {
  //Value
  const profile = useProfile();
  const history = useHistory();

  //State
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [emailErrorMessage, setEmailErrorMessage] = useState(null);
  const [focusInput, setFocusInput] = useState(null);
  //End of state

  //Function
  const addPopup = useAddPopup();
  const genOtp = useGenOtp();

  const handlePressInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key.toLowerCase() === 'enter') {
      onPressBtnGenOtp();
      event.preventDefault();
    }
  };

  const validateEmail = () => {
    let isContinue = true;

    if (!email || email == '') {
      isContinue = false;
      setEmailErrorMessage('Chưa nhập email');
    } else setEmailErrorMessage(null);

    return isContinue;
  };

  const onPressBtnGenOtp = () => {
    const isEmail = validateEmail();

    if (isEmail) {
      genOtp(email)
        .then((data) => {
          if (data) {
            history.push(`/auth-screen/confirm-forgot-password?email=${email}`);
          } else {
            addPopup({
              error: { message: 'Email này chưa đăng ký', title: 'Đã có lỗi xảy ra!' },
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  //Component
  //1
  const emailInput = () => {
    return (
      <>
        <div
          className={`forgot-password-input ${focusInput == 1 ? 'focus-input' : ''} ${
            email ? 'validate-input' : ''
          }`}
        >
          <div className="forgot-password-input-title">Email</div>
          <input
            type="text"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(1);
            }}
            onBlur={() => {
              validateEmail();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 1}
            onKeyDown={handlePressInput}
          />
        </div>
        <div style={{ color: 'red', paddingLeft: 2 }}>{emailErrorMessage}</div>
      </>
    );
  };

  //Main
  return profile ? (
    <Redirect to="/" />
  ) : (
    <div className="forgot-password-form">
      <div className="forgot-password-title">QUÊN MẬT KHẨU</div>
      <form>{emailInput()}</form>
      <div
        className="forgot-password-btn"
        onClick={() => {
          onPressBtnGenOtp();
        }}
      ></div>
      <div className="forgot-password-confirm-text-container">
        J.V.S 株式会社 Telecom-Express-Order-Laptop-Reuse <br /> Website:{' '}
        <a href="https://jvscorp.jp">https://jvscorp.jp</a> <br />
        Email: support@jvscorp.jp <br /> Phone: <a href="tel:070-8332-6668">07083326668</a>
        <br />
        Android & Ios App: JVS Connect
      </div>
    </div>
  );
};

export default ForgotPassword;
