import { useState, useEffect } from 'react';
import React from 'react';
import './SimPrice.css';
import { useGetMySimPrice } from 'src/api/simPriceApi';
import { SimPriceType } from 'src/api/models';

const SimPrice: React.FC = () => {
  //State
  const [simPriceList, setSimPriceList] = useState<SimPriceType[]>(null);

  //Function
  const getSimPrice = useGetMySimPrice();

  useEffect(() => {
    const date = new Date();
    const d = date.getDate();
    const m = date.getMonth() + 1;
    const y = date.getFullYear();
    const temp = `${d}/${m}/${y}`;
    getSimPrice(temp)
      .then((data) => {
        console.log(data);
        setSimPriceList(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getSimPrice]);

  //Main
  return (
    <div className="sim-price-container">
      <div className="ordered-list-title">Bảng giá dành cho CTV</div>
      <div>JVS xin gửi tới quý CTV bảng giá hiện tại</div>
      <table className="table-sim-price">
        <tr>
          <th>Tên</th>
          <th>Giá</th>
          <th>Tiền cọc</th>
          <th>Cước tháng</th>
        </tr>
        {simPriceList
          ? simPriceList.map((v) => {
              return (
                <tr>
                  <td>{v.product.productName}</td>
                  <td>{v.price}</td>
                  <td>{v.deposit}</td>
                  <td>{v.monthlySubscriptionFee}</td>
                </tr>
              );
            })
          : null}
      </table>
    </div>
  );
};

export default SimPrice;
