import React, { useState, useEffect } from 'react';
import { SimNewsType } from 'src/api/models';
import { useHistory } from 'react-router';
import './SimNews.css';
import { useGetSimNews } from 'src/api/simNewsApi';
import useQuery from 'src/hooks/useQuery';

const SimNews: React.FC = () => {
  //Value
  const history = useHistory();
  const query = useQuery();
  const page = query.get('page') ? Number(query.get('page')) : 1;
  const size = query.get('size') ? Number(query.get('size')) : 20;

  //State
  const [simNewsList, setSimNewsList] = useState<SimNewsType[]>(null);
  const [maxPage, setMaxPage] = useState(null);
  const [count, setCount] = useState(null);
  const [elmOption, setElmOption] = useState<JSX.Element[]>(null);

  //Function
  const getSimNews = useGetSimNews();

  //useEffect
  useEffect(() => {
    getSimNews(page, size)
      .then((data) => {
        const temp = Math.ceil(data.count / size);
        const arr: JSX.Element[] = [];

        for (let i = 1; i <= temp; i++) {
          const elm = <option value={i}>{i}</option>;
          arr.push(elm);
        }

        setElmOption(arr);
        setCount(data.count);
        setMaxPage(temp);
        setSimNewsList(data.items);
      })
      .catch((error) => {
        console.log('sim news error');
        console.log(error);
      });
  }, [getSimNews, page, size]);
  //End of useEffect

  //Component
  const list = () => {
    return (
      <div className="row p-0 m-0 justify-content-center">
        <div className="col-12 p-1 m-0 ordered-list-wrapper">
          <div className="ordered-list-title">TIN TỨC</div>
          <div className="ordered-list-header">
            <div className="ordered-list-paging">
              <div
                className="order-item-btn minus"
                onClick={() => {
                  if (page > 1) {
                    const url = `sim-news?page=${page - 1}&size=${size}`;
                    history.push(url);
                  }
                }}
              >
                <i className="fas fa-angle-left"></i>
              </div>
              <div>
                {count == 0 ? 0 : (page - 1) * size + 1}
                {count > 0 ? '-' : null}
                {count > 0 ? (page * size < count ? page * size : count) : null}
                {count > 0 ? '/' : null}
                {count > 0 ? count : null}
              </div>
              {elmOption ? (
                <select
                  className="select-page"
                  value={page}
                  onChange={(event) => {
                    const url = `sim-news?page=${event.target.value}&size=${size}`;
                    history.push(url);
                  }}
                >
                  {elmOption}
                </select>
              ) : null}
              <div
                className="order-item-btn plus"
                onClick={() => {
                  if (page < maxPage) {
                    const url = `sim-news?page=${page + 1}&size=${size}`;
                    history.push(url);
                  }
                }}
              >
                <i className="fas fa-angle-right"></i>
              </div>
              <select
                className="select-size"
                value={size}
                onChange={(event) => {
                  const url = `sim-news?page=1&size=${event.target.value}`;
                  history.push(url);
                }}
              >
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
          <div className="guide-page-main">
            {simNewsList
              ? simNewsList.map((value) => {
                  return (
                    <div
                      className="guide-page-cpm"
                      onClick={() => {
                        const url = `sim-news/${value.simNewId}`;
                        history.push(url);
                      }}
                    >
                      <div className="guide-page-avt">
                        {value.simNewAvatar ? (
                          <img src={value.simNewAvatar} alt="avt" />
                        ) : (
                          <div className="guide-page-no-avt">no-avt</div>
                        )}
                      </div>
                      <div className="guide-page-text">
                        <div className="guide-page-title">{value.simNewTitle}</div>
                        <div className="guide-page-des">
                          <i>{value.description}</i>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    );
  };

  //Main
  return <div className="container-fluid ordered-list-container p-0 m-0 ">{list()}</div>;
};

export default SimNews;
