import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { MonthlySubscriptionFeeType, RolePermisionType, SimType } from './models';

//Sim Api
export const usePostSimError = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (simId: number) => {
      return fetch({
        url: `sccore/rest-api/sim/${simId}/report-error`,
        method: 'post',
      }) as Promise<SimType>;
    },
    [fetch],
  );
};

export const useGetMonthlySubscriptionFee = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (userId: number, fromDate: string, toDate: string) => {
      const temp = {
        userId: userId,
        fromDate: fromDate,
        toDate: toDate,
      };
      return fetch({
        url: `sccore/rest-api/sim/monthly-subscription-fee/me`,
        method: 'post',
        data: temp,
      }) as Promise<MonthlySubscriptionFeeType>;
    },
    [fetch],
  );
};

export const useGetFunctionByUserId = () => {
  const fetch = useFetch();
  return useCallback(
    (userId: number) => {
      return fetch({
        url: `scidm/rest-api/user/${userId}/role-function`,
        method: 'get',
      }) as Promise<RolePermisionType[]>;
    },
    [fetch],
  );
};

export const useGetSimById = () => {
  const fetch = useFetch();
  return useCallback(
    (simId: number) => {
      return fetch({
        url: `sccore/rest-api/sim/${simId}`,
        method: 'get',
      }) as Promise<SimType>;
    },
    [fetch],
  );
};
//End of sim
