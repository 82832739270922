import { createAction } from '@reduxjs/toolkit';
import { EventButton } from 'src/api/models';
import { Profile } from './reducer';

export type PopupContent = {
  txn?: {
    success: boolean;
    summary?: string;
  };
  error?: {
    message: string;
    title: string;
  };
  view?: {
    width?: string;
    height?: string;
    title: string;
    data?: any;
    isManualRemove: boolean;
    posX?: string;
    posY?: string;
    isContext?: boolean;
  };
  confirm?: {
    width: string;
    height: string;
    question: string;
    listActionButton: EventButton[];
    posX?: string;
    posY?: string;
  };
  context?: {
    width: string;
    height: string;
    listActionButton: EventButton[];
    posX?: string;
    posY?: string;
  };
};

export const addPopup =
  createAction<{
    key?: string;
    removeAfterMs?: number | null;
    content: PopupContent;
  }>('app/addPopup');

//type: data, all, context, notification
export const removePopup = createAction<{ key: string; type: string }>('app/removePopup');

export const setNotiCount = createAction<{ notiCount: number }>('app/setNotiCount');

export const login = createAction<{ profile: Profile }>('app/login');

export const setIsContinue = createAction<{ isContinue: boolean }>('app/setIsContinue');

export const logout = createAction('app/logout');

export const reloadTable = createAction('app/reloadTable');

export const setHightlightProduct = createAction<{ index: number }>('app/setHightlightProduct');
