import React, { useCallback } from 'react';
import { useRemovePopup } from 'src/state/application/hooks';
import styled from 'styled-components';
import './DataPopup1.css';

interface IDataPopup {
  width?: string;
  height?: string;
  popupTitle?: string;
  keyPopup?: string;
  data?: JSX.Element;
  isContext?: boolean;
  posX?: string;
  posY?: string;
  isManual?: boolean;
}
interface IWrapper {
  width?: string;
  height?: string;
  backGround?: string;
  isContext?: boolean;
}

const DataPopup1: React.FC<IDataPopup> = (props) => {
  const removePopup = useRemovePopup();

  const removeThisPopup = useCallback(
    () => removePopup(props.keyPopup, 'data'),
    [props.keyPopup, removePopup],
  );
  function onClosePopup(): void {
    removeThisPopup();
  }
  return (
    <WrapperContainer top={props.posY} left={props.posX}>
      {!props.isContext ? (
        <div className="header-data-popup">
          {props.popupTitle}
          <div
            className="btn-close-data-popup"
            onClick={() => {
              onClosePopup();
            }}
          >
            <div></div>
            <div></div>
          </div>
        </div>
      ) : null}
      <WrapperPopup width={props.width} height={props.height}>
        {props.data}
      </WrapperPopup>
    </WrapperContainer>
  );
};

export default DataPopup1;

const WrapperPopup = styled.div((_props: IWrapper) => {
  return {
    width: _props.width ? _props.width : 'fit-content',
    height: _props.height ? _props.height : 'fit-content',
  };
});

const WrapperContainer = styled.div<{ top?: string; left?: string }>`
  top: ${({ top }) => top || '50%'};
  left: ${({ left }) => left || '50%'};
  position: fixed;
  z-index: 150;
  width: fit-content;
  height: fit-content;
  border-radius: 5px;
  transition: 1s;
  box-shadow: 2px 2px 2px lightgray, -2px 2px 2px lightgray, 2px -2px 2px lightgray,
    -2px -2px 2px lightgray;
  transform: translate(-50%, -50%);
`;
