import React from 'react';
import Images from 'src/assets/img';
import useProfile from 'src/hooks/useProfile';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import './AuthScreen.css';
import Login from './Login/Login';
import Verify from './ChangePassword/ChangePassword';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import ConfirmForgotPassword from './ConfirmForgotPassword/ConfirmForgotPassword';
import { useAddPopup, useGetIsContinue, useSetIsContinue } from 'src/state/application/hooks';
import Confirm from './Confirm/Confirm';
import { useEffect } from 'react';
import { useGetFunctionByUserId } from 'src/api/simApi';
import { EnumDisplayConfig, RolePermisionType } from 'src/api/models';

const AuthScreen: React.FC = () => {
  //Value
  const profile = useProfile();
  const isContinue = useGetIsContinue();

  //Function
  const getFunctionByUserId = useGetFunctionByUserId();
  const setIsContinue = useSetIsContinue();
  const addPopup = useAddPopup();

  //Component
  const logo = () => {
    return (
      <div className="logo-container">
        <NavLink to={'/'}>
          <img className="login-logo" src={Images.logo} alt="logo" />
        </NavLink>
      </div>
    );
  };

  const logoImage = () => {
    return (
      <div className="login-image">
        <img src={Images.loginImage} alt="" />
      </div>
    );
  };

  useEffect(() => {
    if (profile) {
      let isContinue = false;
      getFunctionByUserId(profile.info.userId)
        .then((v) => {
          const fList = v;
          const functionList: RolePermisionType[] = [];

          fList.map((value) => {
            let isAdd = true;
            functionList.map((v) => {
              if (v.functionId == value.functionId || value.actions.View == false) {
                isAdd = false;
              }
            });
            if (isAdd) {
              functionList.push(value);
            }
          });

          functionList.map((value) => {
            if (value.functionId == EnumDisplayConfig.Sim_order) {
              isContinue = true;
            }
          });
          setIsContinue(isContinue);
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra!',
              message: error.errorMessage,
            },
          });
        });
    }
  }, [addPopup, getFunctionByUserId, profile, setIsContinue]);

  const main = () => {
    return (
      <div className="login-main">
        {logoImage()}
        <Switch>
          <Route path="/auth-screen/forgot-password" exact>
            <ForgotPassword />
          </Route>
          <Route path="/auth-screen/confirm-forgot-password" exact>
            <ConfirmForgotPassword />
          </Route>
          <Route path="/auth-screen/change-password" exact>
            <Verify />
          </Route>
          <Route path="/auth-screen" exact>
            <Login />
          </Route>
        </Switch>
      </div>
    );
  };
  //End of component

  //useEffect

  //Main
  return !profile ? (
    <div className="login-container">
      {logo()}
      {main()}
    </div>
  ) : !isContinue ? (
    <div className="login-container">
      {logo()}
      <div className="login-main">
        {logoImage()}
        <Confirm />
      </div>
    </div>
  ) : (
    <Redirect to="/" />
  );
};

export default AuthScreen;
