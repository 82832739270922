import { useState } from 'react';
import React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import './SimNewsDetail.css';
import { useGetSimNewsById } from 'src/api/simNewsApi';

const SimNewsDetail: React.FC = () => {
  const params = useParams<{ simNewId: string }>();
  const simNewId = params.simNewId;

  //State
  const [value, setValue] = useState(null);
  const [title, setTitle] = useState(null);

  //Function
  const getSimNewsById = useGetSimNewsById();

  useEffect(() => {
    if (simNewId) {
      getSimNewsById(Number(simNewId))
        .then((data) => {
          setValue(data.simNewValue);
          setTitle(data.simNewTitle);
        })
        .catch((error) => {
          console.log('sim news detail error');
          console.log(error);
        });
    }
  }, [getSimNewsById, simNewId]);

  return value ? (
    <div className="p-2">
      <div className="ordered-list-title">{title}</div>
      <div
        className="p-0 m-1"
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      />
    </div>
  ) : null;
};

export default SimNewsDetail;
