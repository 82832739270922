export type StatisticType = 'price' | 'supply' | 'marketcap';

export type PageData<T> = {
  count: number;
  items: T[];
};

export type NfcUser = {
  title: string;
  description: string;
  company: string;
  birthday: number;
  displayOrder: number;
};

export type NfcChannel = {
  nfcChannelId?: number;
  channelTypeId: number;
  personalUrl: string;
  displayOrder: number;
};

export type NfcInfo = {
  nfcId: number;
  nfcKey: string;
  thumbAvatar: string;
  nfcUser: NfcUser;
  nfcChannels: NfcChannel[];
};

export type RealmType = {
  productRealmId: number;
  productRealmCode: string;
  productRealmName: string;
  displayOrder?: number;
  isHidden?: boolean;
};

export type ChannelTypeInfo = {
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  displayOrder: number;
  channelTypeId: number;
  channelName: string;
  icon: string;
  channelUrl: string;
};

export enum EnumAction {
  View,
  Delete,
  Edit,
  Add,
  Confirm,
  Cancel,
}

export enum EnumFilter {
  all = '',
  stocking = '1',
  outOfStock = '2',
}

export type EventButton = {
  name: string;
  icon: string;
  actionType: EnumAction;
  action: (...args: any[]) => any;
  buttonClass: string;
  align: string;
};

//Category
export type productCategoryAttributeType = {
  productCategoryAttributeId?: number;
  productCategoryId: number;
  attributeTitle: string;
  attributeName: string;
  attributeType: number;
  isShowProduct: number;
  isShowProductSerial: number;
  isShowProductSerialDetail: number;
};

export type CategoryType = {
  productCategoryId: number;
  productRealmId: number;
  productCategoryCode: string;
  productCategoryName: string;
  productRealm: RealmType;
  productCategoryAttributes?: productCategoryAttributeType[] | null;
  displayOrder?: number;
};

//Product
export type ProductType = {
  productId: number;
  productCategoryId: number;
  productCode: string;
  productName: string;
  price?: number;
  monthlySubscriptionFee?: number | null | undefined;
  phoneNumber?: string | null | undefined;
  specifications?: string | null | undefined;
  description?: string | null | undefined;
  cpu?: string | null | undefined;
  ram?: string | null | undefined;
  ssd?: string | null | undefined;
  hdd?: string | null | undefined;
  gpu?: string | null | undefined;
  display?: string | null | undefined;
  isSerial?: number;
  thumbAvatar?: string | null | undefined;
  avatar?: string | null | undefined;
  images?: ImageType[] | null | undefined;
  isStockRemain?: boolean;
  stockRemainQuantity?: number;
  introContent?: string; //Here
  discountPercent?: number;
  displayOrder?: number;
  isHiddenSerial?: boolean;
};

export type ImageType = {
  fileId: number;
  fileTypeId: number;
  fileName: string;
  fileUrl: string;
  thumbUrl: string;
};

//Product Serial
export type ProductSerialType = {
  productSerialId?: number;
  productId?: number;
  productCode?: string;
  productName?: string;
  serial?: string;
  price?: number;
  monthlySubscriptionFee?: number;
  phoneNumber?: string | null | undefined | number;
  specifications?: string | null | undefined;
  description?: string | null | undefined;
  cpu?: string;
  ram?: string;
  ssd?: string;
  hdd?: string;
  display?: string;
  gpu?: string;
  status?: number;
  thumbAvatar?: string;
  avatar?: string;
  images?: ImageType[];
  effectiveDate?: string;
  expirationDate?: string;
  displayOrder?: number;
};

//Company info
export type CompanyInfoType = {
  companyInfoId: number;
  companyInfoKey: string;
  companyInfoTitle: string;
  companyInfoValue: string;
  href: string;
};

export interface IDataBodyItem {
  keyword: string;
  page: number;
  size: number;
  title: string;
  listRealmIds?: number[];
  listCategoryIds?: number[];
}

//Static page
export type StaticPageType = {
  staticPageId: number;
  staticPageKey: string;
  staticPageTitle: string;
  staticPageValue: string;
  description: string;
};

export type ProductGiftType = {
  productGiftId?: number;
  productId: number;
  productGiftValue: string;
};

//Order requirement
export type OrderRequirementDetailsItemType = {
  orderRequirementDetailId?: number;
  productSerialId?: number;
  productSerial?: ProductSerialType;
};

export type OrderRequirementType = {
  orderRequirementId?: number;
  customerId?: number;
  customerFullname?: string;
  customerPhoneNumber?: string;
  customerAddress?: string;
  receiverFullname?: string;
  receiverPhoneNumber?: string;
  receiverAddress?: string;
  orderRequirementNote?: string;
  paymentMethod?: number;
  orderRequirementDetails?: OrderRequirementDetailsItemType[];
};

//News
export type NewsType = {
  displayOrder?: number;
  newId?: number;
  newAvatar?: string;
  newTitle?: string;
  newValue?: string;
  description?: string;
  commentLength?: number;
};

//Comment
export type CommentType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  commentId?: number;
  newId?: number;
  parentId?: number;
  commentValue?: string;
  commentLength?: number;
  avataUrl?: string;
  fullName?: string;
};

//Service
export type ServiceType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  serviceId?: number;
  serviceCode?: string;
  serviceName?: string;
  staticPageKey?: string;
};

//Tracking
export type TrackingStatusType = {
  trackingID?: string;
  statusName?: string;
  note?: string;
  date?: string;
};

export type TrackingDetailType = {
  trackingID?: string;
  statusID?: number;
  statusName?: string;
  status?: boolean;
};

export type TrackingType = {
  status?: number;
  message?: string;
  content?: {
    trackingDetail?: TrackingDetailType[];
    trackingStatus?: TrackingStatusType[];
  };
};

//SIM ORDER
export enum EnumSimOrderPaymentStatus {
  Waiting = 1,
  Paid = 2,
}

export enum EnumSimOrderProgressStatus {
  Waiting = 1,
  Sent = 2,
  Error = 3,
  RecallDefectiveGoods = 4,
}

export type SimOrderType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  fullname: string;
  phoneNumber: string;
  zipCode: string;
  address1?: string;
  address2: string;
  address3?: string;
  address4?: string;
  note?: string;
  progressStatus?: EnumSimOrderProgressStatus;
  paymentStatus?: EnumSimOrderPaymentStatus;
  simOrderDetails?: SimOrderDetailsType[];
  displayOrder?: number;
  simOrderId?: number;
  userId?: number;
  image?: string;
  thumbImage?: string;
  trackingCode?: string;
};

export type SimOrderDetailsType = {
  simOrderDetailId?: number;
  productId?: number;
  quantity?: number;
  product?: ProductType;
  simPhoneNumber?: number;
  paymentStatus?: EnumSimOrderPaymentStatus;
  price?: number;
  isCreatedSim?: boolean;
};

//Change password
export type ChangePasswordResult = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  userId?: number;
  birthday?: string;
  email: string;
  fullName?: string;
  groupId?: number;
  langId?: number;
  loginName?: string;
  password?: string;
  currentPassword?: string;
  confirmPassword?: string;
  telephone?: string | number;
  mainAddress?: string;
  facebookId?: string;
  googleId?: string;
  twitterId?: string;
  cmtnd?: null;
  cccd?: null;
  mobile?: null;
  avata?: null;
  avataUrl?: string;
  sex?: string;
  occupation?: string;
  fbAccessToken?: string;
  googleAccessToken?: string;
  cover?: string;
  loginNameHash?: string;
  refreshToken?: string;
};

//Sim
export enum EnumSimStatus {
  Actice = 1,
  Deactive = 2,
  Error = 3,
  StopService = 4,
}

//Sim store
export type SimStoreType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  simStoreId?: number;
  simStoreTitle?: string;
  simStoreAddress?: string;
};

export type SimType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  simId?: number;
  userId?: number;
  simStoreId?: number;
  userName?: string;
  customerName?: string;
  simPhoneNumber?: string;
  simImei?: string;
  status?: EnumSimStatus;
  productId?: number;
  monthlySubscriptionFee?: number;
  effectiveDate?: string;
  product?: ProductType;
  simStore?: SimStoreType;
  deposit?: number;
};

export type CustomerType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  customerTypeId?: number;
  customerTypeCode?: string;
  customerTypeTitle?: string;
};

export type SimPriceType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  simPriceId?: number;
  customerTypeId?: number;
  productId?: number;
  price?: number;
  monthlySubscriptionFee?: number;
  deposit?: number;
  effectiveDate?: string;
  product?: ProductType;
  customerType?: CustomerType;
};

export type SimPriceDebitType = {
  totalMoney?: number;
  paidMoney?: number;
  remainUnpaidMoney?: number;
  simOrderDetails?: SimOrderDetailsType[];
  sendEmail?: boolean;
  paidOtherMoney?: number;
  totalOtherMoney?: number;
  remainUnpaidOtherMoney?: number;
};

//monthly-subscription-fee
export type MonthlySubscriptionFeeType = {
  totalMoney: number;
  sims: SimType[];
  sendEmail: boolean;
};

export type RolePermisionType = {
  roleId?: number;
  functionId?: number;
  permision?: number;
  actions?: {
    View?: boolean;
    Add?: boolean;
    Confirm?: boolean;
    Edit?: boolean;
    Delete?: boolean;
  };
};

export enum EnumDisplayConfig {
  Loai_san_pham = 2,
  Danh_muc_san_pham = 3,
  San_pham = 4,
  Dich_vu = 5,
  Banner = 6,
  Logo = 7,
  Thong_tin = 8,
  Hinh_anh_cong_ty = 9,
  Trang_thong_tin_tinh = 10,
  Danh_sach_dat_hang = 11,
  Tin_tuc = 12,
  Quan_ly_tai_khoan = 1,
  Cau_hinh_phan_quyen = 21,
  Phan_quyen = 22,
  Sim_order = 13,
  Sim_da_ban = 14,
  Lich_su_bang_gia = 23,
  Mau_email = 24,
  Cong_no = 25,
}

//Note
export type NoteType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  noteId?: number;
  userId?: number;
  functionId?: number;
  objectId?: number;
  noteContent?: string;
  avataUrl?: string;
  fullName?: string;
};

//Notification
export enum EnumNotificationStatus {
  Wait = 0,
  Seen = 1,
  Read = 2,
}

export enum EnumNotiTemplateKey {
  REPORT_SIM_ERROR = 'REPORT_SIM_ERROR',
  STOP_SERVICE = 'STOP_SERVICE',
  NOTE = 'NOTE',
}

export type NotificationType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  notificationId?: number;
  notificationTitle?: string;
  notificationValue?: string;
  receiverId?: number;
  notificationTemplateId?: number;
  notificationTemplateKey?: EnumNotiTemplateKey;
  status?: EnumNotificationStatus;
  fullName?: string;
  avataUrl?: string;
  notificationData?: string;
};

export type MyNotificationType = {
  data: PageData<NotificationType>;
  badge: number;
};

//Guide Page
export type GuidePageType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  guidePageId?: number;
  guidePageKey?: string;
  guidePageTitle?: string;
  guidePageValue?: string;
  description?: string;
  guideAvatar?: string;
};

//Sim news
export type SimNewsType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  simNewId?: number;
  simNewAvatar?: string;
  simNewTitle?: string;
  simNewValue?: string;
  description?: string;
  commentLength?: number;
};

export type SimNewCommentType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  simCommentId?: number;
  simNewId?: number;
  parentId?: number;
  simCommentValue?: string;
  commentLength?: number;
  avataUrl?: string;
  fullName?: string;
};

//Account balance
export type AccountBalanceType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  accountHistoryId?: number;
  userId?: number;
  accountActionType?: number;
  moneyAmount?: number;
  note?: string;
  accountActionTitle?: string;
  coefficient?: number;
  data?: string;
};

export type AccountBalanceMoneyType = {
  userId: number;
  moneyAmount: number;
  note?: string;
};

//Address
export type AddressResultType = {
  message?: string;
  status?: number;
  results?: [
    {
      address1?: string;
      address2?: string;
      address3?: string;
      kana1?: string;
      kana2?: string;
      kana3?: string;
      prefcode?: string;
      zipcode?: string;
    },
  ];
};
