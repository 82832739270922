import React from 'react';
import { EventButton } from 'src/api/models';
import styled from 'styled-components';
import './ConfirmPopup.css';

interface IConfirmPopup {
  width: string;
  height: string;
  question?: string;
  listActionButton?: EventButton[];
  posX?: string;
  posY?: string;
}

const ConfirmPopup: React.FC<IConfirmPopup> = (props) => {
  const elmButton = props.listActionButton
    ? props.listActionButton.map((button: EventButton, index: number) => {
        return (
          <button
            key={'confirm-btn' + index}
            className={`confirm-button ${button.buttonClass}`}
            onClick={button.action}
            style={{ backgroundColor: index == 0 ? 'red' : '#0090ed', color: 'white' }}
          >
            <i className={button.icon} style={{ marginRight: 5 }}></i>
            {button.name}
          </button>
        );
      })
    : null;

  return (
    <WrapperContainer width={props.width} height={props.height}>
      <WrapperColumn>
        <WrapperRow width={'100%'} height={'50%'}>
          {props.question}
        </WrapperRow>
        <WrapperRow width={'100%'} height={'50%'}>
          {elmButton}
        </WrapperRow>
      </WrapperColumn>
    </WrapperContainer>
  );
};

export default ConfirmPopup;

const WrapperContainer = styled.div<{ width: string; height: string }>`
  top: 50%;
  left: 50%;
  position: fixed;
  z-index: 150;
  width: ${({ width }) => width || 'fit-content'};
  height: ${({ height }) => height || 'fit-content'};
  border-radius: 5px;
  overflow: hidden;
  transition: 1s;
  box-shadow: 2px 2px 2px lightgray, -2px 2px 2px lightgray, 2px -2px 2px lightgray,
    -2px -2px 2px lightgray;
  transform: translate(-50%, -50%);
  background-color: white;
  color: black;
`;

const WrapperColumn = styled.div<{ width?: string; height?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
  transition: 1s;
`;
const WrapperRow = styled.div<{ width?: string; height?: string }>`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
  transition: 1s;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  text-align: center;
`;
