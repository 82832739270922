import { useCallback, useMemo } from 'react';
import {
  addPopup,
  PopupContent,
  removePopup,
  login,
  logout,
  setHightlightProduct,
  reloadTable,
  setIsContinue,
  setNotiCount,
} from './actions';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../index';
import { Profile } from './reducer';

// returns a function that allows adding a popup
export function useAddPopup(): (content: PopupContent, key?: string) => void {
  const dispatch = useDispatch();

  return useCallback(
    (content: PopupContent, key?: string) => {
      dispatch(addPopup({ content, key }));
    },
    [dispatch],
  );
}

// returns a function that allows removing a popup via its key
export function useRemovePopup(): (key: string, type: string) => void {
  const dispatch = useDispatch();
  return useCallback(
    (key: string, type: string) => {
      dispatch(removePopup({ key, type }));
    },
    [dispatch],
  );
}

//Writen by Le Van Huy
export function useSetHightLightProduct(): (index: number) => void {
  const dispatch = useDispatch();
  return useCallback(
    (index: number) => {
      dispatch(setHightlightProduct({ index }));
    },
    [dispatch],
  );
}

//Writen by Le Van Huy
export function useActivePopups(): AppState['application']['popupList'] {
  const list = useSelector((state: AppState) => state.application.popupList);
  return useMemo(
    () =>
      list.filter((item) => item.show && (!item.content.view || item.content.view.data.type)),
    [list],
  );
}

export function useSetProfileInfo(): (profile: Profile) => void {
  const dispatch = useDispatch();
  return useCallback(
    (profile: Profile) => {
      dispatch(login({ profile }));
    },
    [dispatch],
  );
}

export function useSetIsContinue(): (isContinue: boolean) => void {
  const dispatch = useDispatch();
  return useCallback(
    (isContinue: boolean) => {
      dispatch(setIsContinue({ isContinue }));
    },
    [dispatch],
  );
}

export function useRemoveProfileInfo(): () => void {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(logout());
  }, [dispatch]);
}

export function useGetProfileInfo() {
  const profile = useSelector((state: AppState) => state.application.profile);
  return profile;
}

//Notifi
export function useGetNotiCount() {
  const notiCount = useSelector((state: AppState) => state.application.notiCount);
  return notiCount;
}

export function useSetNotiCount(): (notiCount: number) => void {
  const dispatch = useDispatch();
  return useCallback(
    (notiCount: number) => {
      dispatch(setNotiCount({ notiCount }));
    },
    [dispatch],
  );
}

export function useGetIsContinue() {
  const isContinue = useSelector((state: AppState) => state.application.isContinue);
  return isContinue;
}

export function useReloadTable(): () => void {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(reloadTable());
  }, [dispatch]);
}

export function useGetStatusReload() {
  const reload = useSelector((state: AppState) => state.application.reloadFlag);
  return reload;
}

export function useGetHightlightProduct() {
  const index = useSelector((state: AppState) => state.application.hightlightProduct);
  return index;
}

export function useCleanScreen() {
  const removePopup = useRemovePopup();
  const setHightlightProduct = useSetHightLightProduct();
  removePopup('anything', 'all');
  setHightlightProduct(null);
  return () => {
    removePopup('anything', 'all');
    setHightlightProduct(null);
  };
}
