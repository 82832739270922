import { useState } from 'react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Images from 'src/assets/img';
import './Header.css';
import { useGetNotification, useSeenNotification } from 'src/api/notificationApi';
import { useEffect } from 'react';
import { useGetNotiCount, useSetNotiCount } from 'src/state/application/hooks';

interface IHeader {
  functionShowNav: (...args: any[]) => any;
}

const Header: React.FC<IHeader> = (props) => {
  //Value
  const notiCount = useGetNotiCount();
  //State
  const [reloadFlag, setReloadFlag] = useState(false);

  //Function
  const clickBtnNav = props.functionShowNav;
  const setNotiCount = useSetNotiCount();
  const seenNotification = useSeenNotification();
  const getNotification = useGetNotification();

  useEffect;
  useEffect(() => {
    getNotification(1, 1)
      .then((data) => {
        setNotiCount(data.badge);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getNotification, reloadFlag, setNotiCount]);

  //Component
  const headerBar = () => {
    return (
      <div className="header-container mobile top">
        <div className="header-logo-left">
          <img src={Images.logo} alt="" />
        </div>
        <div className="header-icon-right">
          <NavLink to={'/guide-page'} activeClassName="focus">
            <div className="header-nav-item header-bar">
              <i className="fas fa-lightbulb"></i>
            </div>
          </NavLink>
          <NavLink to={'/sim-news'} activeClassName="focus">
            <div className="header-nav-item header-bar">
              <i className="fas fa-ad"></i>
            </div>
          </NavLink>
          <NavLink to={'/notification'} activeClassName="focus">
            <div
              className="header-nav-item header-bar"
              onClick={() => {
                seenNotification()
                  .then(() => {
                    setReloadFlag(!reloadFlag);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
            >
              <i className="fas fa-bell"></i>
              {notiCount && notiCount > 0 ? (
                <div className="header-bar-count">{notiCount}</div>
              ) : null}
            </div>
          </NavLink>
        </div>
      </div>
    );
  };
  const mobileNav = () => {
    return (
      <div className="header-container mobile">
        <NavLink to={'/'} activeClassName="focus" exact>
          <div className="header-nav-item">
            <i className="fas fa-folder-plus"></i>
            <div>Lên đơn</div>
          </div>
        </NavLink>
        <NavLink to={'/ordered-list'} activeClassName="focus">
          <div className="header-nav-item">
            <i className="fas fa-list"></i>
            <div>D.S Đơn</div>
          </div>
        </NavLink>
        <NavLink to={'/active-sim-list'} activeClassName="focus">
          <div className="header-nav-item">
            <i className="fas fa-sim-card"></i>
            <div>D.S Sim</div>
          </div>
        </NavLink>
        <NavLink to={'/account'} activeClassName="focus">
          <div className="header-nav-item">
            <i className="fas fa-user-circle"></i>
            <div>Tài khoản</div>
          </div>
        </NavLink>
      </div>
    );
  };

  const pcNav = () => {
    return (
      <div className={`header-container pc`} id="header-pc">
        <div className="header-list-nav pc">
          <NavLink to={'/'} activeClassName="focus" exact>
            <div className="header-nav-item pc" onClick={clickBtnNav}>
              <i className="fas fa-folder-plus"></i>
              LÊN ĐƠN
            </div>
          </NavLink>
          <NavLink to={'/ordered-list'} activeClassName="focus">
            <div className="header-nav-item pc" onClick={clickBtnNav}>
              <i className="fas fa-list"></i>
              DANH SÁCH ĐƠN
            </div>
          </NavLink>
          <NavLink to={'/active-sim-list'} activeClassName="focus">
            <div className="header-nav-item pc" onClick={clickBtnNav}>
              <i className="fas fa-sim-card"></i>
              DANH SÁCH SIM
            </div>
          </NavLink>
          <NavLink to={'/account'} activeClassName="focus">
            <div className="header-nav-item pc" onClick={clickBtnNav}>
              <i className="fas fa-user-circle"></i>
              TÀI KHOẢN
            </div>
          </NavLink>
        </div>
      </div>
    );
  };
  //End of component

  //Main
  return (
    <div className="header-wrapper">
      {pcNav()}
      {mobileNav()}
      {headerBar()}
    </div>
  );
};

export default Header;
