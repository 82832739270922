import './Login.css';
import React, { useCallback, useState } from 'react';
import { useConfiguration } from 'src/contexts/ConfigProvider/ConfigProvider';
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { useAddPopup, useSetIsContinue, useSetProfileInfo } from 'src/state/application/hooks';
import { useGetUserInfo, useLoginGoogle, useLoginWithEmail } from 'src/api/backend-api';
import { NavLink } from 'react-router-dom';
import useQuery from 'src/hooks/useQuery';
import { useGetFunctionByUserId } from 'src/api/simApi';
import { EnumDisplayConfig, RolePermisionType } from 'src/api/models';

const Login: React.FC = () => {
  //Value
  const clientId = useConfiguration().clientId;
  const query = useQuery();
  const mail = query.get('email');

  //State
  const [email, setEmail] = useState<string | undefined>(mail);
  const [emailErrorMessage, setEmailErrorMessage] = useState(null);

  const [password, setPassword] = useState<string | undefined>(undefined);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(null);

  const [focusInput, setFocusInput] = useState(null);
  //End of state

  //Function
  const addPopup = useAddPopup();
  const loginWithEmail = useLoginWithEmail();
  const loginGoogle = useLoginGoogle();

  const setProfileInfo = useSetProfileInfo();
  const getUserInfo = useGetUserInfo();

  const setIsContinue = useSetIsContinue();
  const getFunctionByUserId = useGetFunctionByUserId();

  const validateEmail = () => {
    let isContinue = true;
    if (!email || email == '') {
      isContinue = false;
      setEmailErrorMessage('Chưa nhập email');
    } else setEmailErrorMessage(null);

    return isContinue;
  };

  const validatePassword = () => {
    let isContinue = true;
    if (!password || password == '') {
      isContinue = false;
      setPasswordErrorMessage('Chưa nhập mật khẩu');
    } else setPasswordErrorMessage(null);

    return isContinue;
  };

  const onPressBtnLogin = () => {
    const isEmail = validateEmail();
    const isPassword = validatePassword();
    if (isEmail && isPassword) {
      onLogin();
    }
  };

  const onLogin = useCallback(() => {
    loginWithEmail(email, password)
      .then((data) => {
        getUserInfo(data.token)
          .then((profile) => {
            setProfileInfo({
              accessToken: data.token,
              refreshToken: data.refreshToken,
              info: profile,
            });
          })
          .catch((error) => {
            addPopup({
              error: {
                message: error.errorMessage,
                title: 'Đã có lỗi xảy ra!',
              },
            });
          });
      })
      .catch((error) => {
        addPopup({
          error: {
            message: error.errorMessage,
            title: 'Đã có lỗi xảy ra!',
          },
        });
      });
  }, [loginWithEmail, email, password, getUserInfo, setProfileInfo, addPopup]);

  const onLoginGoogle = useCallback(
    (rs: GoogleLoginResponse | GoogleLoginResponseOffline) => {
      if (rs && (rs as GoogleLoginResponse).accessToken) {
        const response = rs as GoogleLoginResponse;
        const googleId = response.profileObj.googleId;
        const email = response.profileObj.email;
        const accessToken = response.accessToken;
        const fullName = `${response.profileObj.givenName} ${response.profileObj.familyName}`;
        const avatarUrl = response.profileObj.imageUrl;
        loginGoogle(googleId, email, avatarUrl, fullName, accessToken)
          .then((data) => {
            getUserInfo(data.token)
              .then((profile) => {
                setProfileInfo({
                  accessToken: data.token,
                  refreshToken: data.refreshToken,
                  info: profile,
                });
              })
              .catch((error) => {
                // addPopup({
                //   error: {
                //     message: error.errorMessage,
                //     title: 'Đã có lỗi xảy ra!',
                //   },
                // });
                console.log(error);
              });
          })
          .catch((error) => {
            // addPopup({
            //   error: {
            //     message: error.errorMessage,
            //     title: 'Đã có lỗi xảy ra!',
            //   },
            // });
            console.log(error);
          });
      } else {
        // addPopup({
        //   error: {
        //     message: JSON.stringify(rs),
        //     title: 'Đã có lỗi xảy ra!',
        //   },
        // });
        console.log(JSON.stringify(rs));
      }
    },
    [getUserInfo, loginGoogle, setProfileInfo],
  );

  const handlePressInput = (event) => {
    const form = event.target.form;
    const index = [...form].indexOf(event.target);

    if (event.key.toLowerCase() === 'enter') {
      if (focusInput == 1) {
        form.elements[index + 1].focus();
        event.preventDefault();
      } else {
        onPressBtnLogin();
      }
    } else if (event.key.toLowerCase() === 'arrowdown') {
      if (focusInput == 1) {
        form.elements[index + 1].focus();
        setFocusInput(2);
        event.preventDefault();
      }
    } else if (event.key.toLowerCase() === 'arrowup') {
      if (focusInput == 2) {
        setFocusInput(1);
        form.elements[index - 1].focus();
        event.preventDefault();
      }
    }
  };
  //End of function

  //Main
  return (
    <div className="login-form">
      <div className="login-title">ĐĂNG NHẬP TÀI KHOẢN</div>
      <form>
        <div
          className={`login-input ${focusInput == 1 ? 'focus-input' : ''} ${
            email ? 'validate-input' : ''
          }`}
        >
          <div className="login-input-title">Email</div>
          <input
            type="text"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(1);
            }}
            onKeyDown={handlePressInput}
            onBlur={() => {
              validateEmail();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 1}
          />
        </div>
        <div style={{ color: 'red', paddingLeft: 2 }}>{emailErrorMessage}</div>
        <div
          className={`login-input ${focusInput == 2 ? 'focus-input' : ''} ${
            password ? 'validate-input' : ''
          }`}
        >
          <div className="login-input-title">Mật khẩu</div>
          <input
            type="password"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(2);
            }}
            onKeyDown={handlePressInput}
            onBlur={() => {
              validatePassword();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 2}
          />
        </div>
        <div style={{ color: 'red', paddingLeft: 2 }}>{passwordErrorMessage}</div>
      </form>
      <div className="text-btn-login-container">
        <NavLink to={'/auth-screen/forgot-password'}>Quên mật khẩu</NavLink>
        <NavLink to={'/auth-screen/change-password'}> Đổi mật khẩu</NavLink>
      </div>
      <button
        className="login-btn"
        onClick={() => {
          onPressBtnLogin();
        }}
      ></button>
      <div className="login-google">
        <GoogleLogin
          clientId={clientId}
          buttonText="Đăng nhập với Google"
          onSuccess={onLoginGoogle}
          onFailure={onLoginGoogle}
        />
      </div>
      <div className="login-confirm-text-container">
        J.V.S 株式会社 Telecom-Express-Order-Laptop-Reuse <br /> Website:{' '}
        <a href="https://jvscorp.jp">https://jvscorp.jp</a> <br />
        Email: support@jvscorp.jp <br /> Phone: <a href="tel:070-8332-6668">07083326668</a>
        <br />
        Android & Ios App: JVS Connect
      </div>
    </div>
  );
};

export default Login;
