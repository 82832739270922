import React from 'react';
import { ProductType, SimPriceType } from 'src/api/models';
import './OrderItem.css';

interface IOrderItem {
  data: ProductType;
  onDelete: (...args: any[]) => any;
  index: number;
  priceList: SimPriceType[];
}

const OrderItem: React.FC<IOrderItem> = (props) => {
  //Function

  //Main
  return props.data ? (
    <div className="order-item-container">
      <div className="order-item-index">{props.index + 1}</div>
      <div className="order-item-image">
        <img src={props.data.thumbAvatar} alt="avatar" />
      </div>
      <div className="order-item-detail">
        <div className="order-item-title">{props.data.productName}</div>
        {props.priceList.map((value) => {
          if (value.productId == props.data.productId) {
            return (
              <div className="order-item-des">
                Giá:
                {value.price ? value.price : 0}¥
              </div>
            );
          }
        })}
        {props.priceList.map((value) => {
          if (value.productId == props.data.productId) {
            return (
              <div className="order-item-des">
                Tiền cọc:
                {value.deposit ? value.deposit : 0}¥
              </div>
            );
          }
        })}
        {props.priceList.map((value) => {
          if (value.productId == props.data.productId) {
            return (
              <div className="order-item-des">
                Cước tháng:
                {value.monthlySubscriptionFee ? value.monthlySubscriptionFee : 0}¥
              </div>
            );
          }
        })}
      </div>
      <div
        className="btn-delete-order-item"
        title="Xóa"
        onClick={() => {
          props.onDelete(props.index);
        }}
      >
        <i className="fas fa-trash-alt"></i>
      </div>
    </div>
  ) : null;
};

export default OrderItem;
