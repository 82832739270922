import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Popups from './components/Popups';
import theme from './theme';
import store from './state';
import { Provider } from 'react-redux';
import ModalsProvider from './contexts/Modals';
import { SmartCardProvider } from './contexts/SmartCardProvider/SmartCardProvider';
import Base from './views/Base/Base';
import AuthScreen from './views/AuthScreen/AuthScreen';

const App: React.FC = () => {
  return (
    <Providers>
      <Router key={'router-base'}>
        <Switch key={'switch-base'}>
          <Route key={'route-login'} path="/auth-screen">
            <AuthScreen />
          </Route>
          <Route key={'route-base'} path="/">
            <Base />
          </Route>
        </Switch>
      </Router>
    </Providers>
  );
};

const Providers: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <SmartCardProvider>
          <ModalsProvider>
            <>
              <Popups />
              {children}
            </>
          </ModalsProvider>
        </SmartCardProvider>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
