import React from 'react';
import styled from 'styled-components';
import { useActivePopups } from '../../state/application/hooks';
import { AutoColumn } from '../Column';
import PopupItem from './PopupItem';

const MobilePopupWrapper = styled.div<{ height: string | number }>`
  position: relative;
  width: 100vw;
  display: none;
  height: ${({ height }) => height};
  margin: ${({ height }) => (height ? '0 auto;' : 0)};
  margin-bottom: ${({ height }) => (height ? '20px' : 0)};
  @media (max-width: 991px) {
    display: block;
    margin-bottom: ${({ height }) => (height ? '10px' : 0)};
  }
  position: fixed;
  z-index: 300;
`;

const MobilePopupInner = styled.div`
  height: 99%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const FixedPopupColumn = styled(AutoColumn)`
  position: fixed;
  top: 90px;
  margin-right: 0px;
  right: 1rem;
  max-width: 355px !important;
  width: 100%;
  z-index: 300;

  @media (max-width: 991px) {
    display: none;
  } ;
`;

const Popups: React.FC = () => {
  //value
  const activePopups = useActivePopups();
  const deviceWidth = window.innerWidth;

  if (deviceWidth <= 911) {
    return (
      <MobilePopupWrapper height={activePopups?.length > 0 ? 'fit-content' : 0}>
        <MobilePopupInner>
          {activePopups // reverse so new items up front
            .slice(0)
            .reverse()
            .map((item) => (
              <PopupItem
                key={item.key}
                content={item.content}
                popKey={item.key}
                removeAfterMs={item.removeAfterMs}
              />
            ))}
        </MobilePopupInner>
      </MobilePopupWrapper>
    );
  } else {
    return (
      <FixedPopupColumn gap="20px">
        {activePopups.map((item) => (
          <PopupItem
            key={item.key}
            content={item.content}
            popKey={item.key}
            removeAfterMs={item.removeAfterMs}
          />
        ))}
      </FixedPopupColumn>
    );
  }
};
export default Popups;
