import loginImage from './img-dk-mobile.png';
import logo from './logo.png';
import error from './1200px-Error.svg.png';

const Images = {
  loginImage: loginImage,
  logo: logo,
  error: error,
};

export default Images;
