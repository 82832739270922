import { useEffect, useState } from 'react';
import React from 'react';
import {
  EnumAction,
  EnumDisplayConfig,
  EnumSimOrderProgressStatus,
  EventButton,
  NoteType,
  SimOrderDetailsType,
  SimOrderType,
} from 'src/api/models';
import SimDetailItem from 'src/components/SimDetailItem/SimDetailItem';
import { useGetNote, usePostNote } from 'src/api/noteApi';
import { useAddPopup, useRemovePopup } from 'src/state/application/hooks';
import { useDeleteSimOrder, useGetSimOrderById, usePutSimOrder } from 'src/api/backend-api';
import { useHistory, useParams } from 'react-router';
import './OrderDetail.css';
import useProfile from 'src/hooks/useProfile';

type NoteByDay = {
  createdAt: string;
  list: NoteType[];
};

const OrderDetail: React.FC = () => {
  //Value
  const history = useHistory();
  const BASE_TRACKING_URL =
    'https://trackings.post.japanpost.jp/services/srv/search/direct?reqCodeNo1=';
  const params = useParams<{ simOrderId: string }>();
  const simOrderId = Number(params.simOrderId);
  const profile = useProfile();

  //State
  const [simOrder, setSimOrder] = useState(null);
  const [isShowFullImage, setIsShowFullImage] = useState(false);
  const [noteContent, setNoteContent] = useState('');
  const [reloadFlag, setReloadFlag] = useState<boolean>(false);
  const [noteList, setNoteList] = useState<NoteByDay[]>(null);

  //Function
  const getNote = useGetNote();
  const postNote = usePostNote();
  const putSimOrder = usePutSimOrder();
  const deleteSimOrder = useDeleteSimOrder();
  const getSimOrderById = useGetSimOrderById();
  const addPopup = useAddPopup();
  const removePopup = useRemovePopup();

  //TODO
  const onConfirmReportError = () => {
    const onViewError = () => {
      removePopup('anything', 'context');
      removePopup('anything', 'data');
      const url = `/guide-page`;
      history.push(url);
    };
    const onReportError = () => {
      removePopup('anything', 'context');
      removePopup('anything', 'data');

      const temp: SimOrderType = simOrder;
      temp.progressStatus = EnumSimOrderProgressStatus.Error;

      putSimOrder(temp)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Báo lỗi thành công!',
            },
          });
          setReloadFlag(!reloadFlag);
          history.goBack();
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const listToDo: EventButton[] = [
      {
        name: 'Xem',
        icon: 'fas fa-check',
        actionType: EnumAction.Confirm,
        action: onViewError,
        buttonClass: 'ordered-icon-btn',
        align: 'center',
      },
      {
        name: 'Báo lỗi',
        icon: 'fas fa-times',
        actionType: EnumAction.Cancel,
        action: onReportError,
        buttonClass: 'ordered-icon-btn',
        align: 'center',
      },
    ];
    addPopup({
      confirm: {
        width: 'max(300px, min(calc((100vw - 991px) * 9999), 400px))',
        height: '150px',
        question: 'Bạn muốn xem một số lỗi thường gặp trước khi báo lỗi không?',
        listActionButton: listToDo,
      },
    });
  };

  const onDeleteSimOrder = () => {
    const onCancel = () => {
      removePopup('anything', 'context');
      removePopup('anything', 'data');
    };
    const onConfirm = () => {
      removePopup('anything', 'context');
      removePopup('anything', 'data');
      deleteSimOrder(simOrder.simOrderId)
        .then(() => {
          setReloadFlag(!reloadFlag);
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa thành công!',
            },
          });
          history.goBack();
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        });
    };
    const listToDo: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'fas fa-check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'ordered-icon-btn',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'fas fa-times',
        actionType: EnumAction.Cancel,
        action: onCancel,
        buttonClass: 'ordered-icon-btn',
        align: 'center',
      },
    ];
    addPopup({
      confirm: {
        width: 'max(300px, min(calc((100vw - 991px) * 9999), 400px))',
        height: '150px',
        question: 'Bạn có chắc muốn xóa đơn hàng này',
        listActionButton: listToDo,
      },
    });
  };

  const onPostNote = () => {
    if (simOrderId) {
      const note: NoteType = {
        functionId: EnumDisplayConfig.Sim_order,
        objectId: Number(simOrderId),
        noteContent: noteContent,
      };
      postNote(note, profile.info.userId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Gửi thành công!',
            },
          });
          setReloadFlag(!reloadFlag);
        })
        .catch((e) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra!',
              message: e.errorMessage,
            },
          });
          setReloadFlag(!reloadFlag);
        });
    }
  };

  //Component
  const infoList = () => {
    if (simOrder)
      return (
        <div className="ordered-input-container">
          <>
            <div className={`ordered-input validate-input`}>
              <div className="ordered-input-title">Tên khách hàng</div>
              <input
                type="text"
                value={simOrder.fullname ? simOrder.fullname : null}
                disabled
              />
            </div>
          </>
          <>
            <div className={`ordered-input validate-input`}>
              <div className="ordered-input-title">Số điện thoại</div>
              <input type="text" value={simOrder.phoneNumber} disabled />
            </div>
          </>
          <>
            <div className={`ordered-input validate-input`}>
              <div className="ordered-input-title">Mã bưu điện</div>
              <input type="text" value={simOrder.zipCode} disabled />
            </div>
          </>
          <>
            <div className={`ordered-input validate-input`}>
              <div className="ordered-input-title">Địa chỉ</div>
              <textarea
                value={`${simOrder.address1 ? `${simOrder.address1}` : ''}${
                  simOrder.address2 ? `\n${simOrder.address2}` : ''
                }${simOrder.address3 ? `\n${simOrder.address3}` : ''}${
                  simOrder.address4 ? `\n${simOrder.address4}` : ''
                }`}
                disabled
              />
            </div>
          </>
          <>
            <div className={`ordered-input validate-input`}>
              <div className="ordered-input-title">Ghi chú</div>
              <input type="text" value={simOrder.note ? simOrder.note : null} disabled />
            </div>
          </>
          <>
            <div className={`ordered-input validate-input`}>
              <div className="ordered-input-title">Mã tracking</div>
              <input
                type="text"
                value={simOrder.trackingCode ? simOrder.trackingCode : null}
                disabled
              />
            </div>
          </>
        </div>
      );
  };

  useEffect(() => {
    if (simOrderId) {
      getSimOrderById(Number(simOrderId))
        .then((data) => {
          setSimOrder(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [getSimOrderById, reloadFlag, simOrderId]);

  useEffect(() => {
    if (simOrderId) {
      getNote(EnumDisplayConfig.Sim_order, Number(simOrderId))
        .then((data) => {
          if (data.length > 0) {
            let cr = data[0].createdAt;
            let tempArr: NoteType[] = [];
            const mainArr: NoteByDay[] = [];

            for (let i = 0; i <= data.length; i++) {
              if (i < data.length) {
                if (data[i].createdAt == cr) {
                  tempArr.push(data[i]);
                } else {
                  const temp: NoteByDay = {
                    createdAt: cr,
                    list: tempArr,
                  };
                  mainArr.push(temp);
                  cr = data[i].createdAt;
                  tempArr = [];
                }
              } else {
                const temp: NoteByDay = {
                  createdAt: cr,
                  list: tempArr,
                };
                mainArr.push(temp);
              }
            }

            setNoteList(mainArr);
          }
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra!',
              message: error.errorMessage,
            },
          });
        });
    }
  }, [addPopup, getNote, reloadFlag, simOrderId]);

  //Main
  return (
    <div className="container-fluid ordered-list-container p-0 m-0 ">
      <div className="row p-0 m-0 justify-content-center">
        <div className="col-lg-5 p-1 m-0 order-detail-blank">
          <div className="ordered-list-title">CHI TIẾT ĐƠN</div>
          {simOrder?.thumbImage ? (
            <div
              className="bill-thumb-image-container"
              title={'Bill Image'}
              onClick={() => {
                setIsShowFullImage(true);
              }}
            >
              <img src={simOrder.thumbImage} alt="thumbImage" />
            </div>
          ) : null}
          <div className="ordered-detail-main">
            {/* {simOrder && simOrder.trackingCode && simOrder.trackingCode != '' ? (
              <span>
                Bấm vào{' '}
                <a href={`${BASE_TRACKING_URL}${simOrder.trackingCode}`} target="_blank">
                  đây
                </a>{' '}
                để tra cứu trạng thái
              </span>
            ) : null} */}
            {infoList()}
            <div className="sim-list">
              {simOrder
                ? simOrder.simOrderDetails.map((value: SimOrderDetailsType) => {
                    return (
                      <SimDetailItem
                        key={`order-item-detail ${value.productId}`}
                        productId={value.productId}
                        simPhoneNumber={value.simPhoneNumber}
                        price={value.price}
                      />
                    );
                  })
                : null}
            </div>
            {simOrder ? (
              <div className="ordered-popup-status">
                <span>Tr.thái vận chuyển: </span>
                <div style={{ fontWeight: 500 }}>
                  {' '}
                  {simOrder.progressStatus == EnumSimOrderProgressStatus.Waiting ? (
                    <span style={{ color: 'gray' }}>Chưa gửi hàng</span>
                  ) : simOrder.progressStatus == EnumSimOrderProgressStatus.Sent ? (
                    <span style={{ color: 'blue' }}>Đã gửi hàng</span>
                  ) : simOrder.progressStatus == EnumSimOrderProgressStatus.Error ? (
                    <span style={{ color: 'red' }}>Lỗi</span>
                  ) : simOrder.progressStatus ==
                    EnumSimOrderProgressStatus.RecallDefectiveGoods ? (
                    <span style={{ color: 'yellowgreen' }}>Thu hồi</span>
                  ) : null}
                </div>
              </div>
            ) : null}
            <div className="ordered-list-title">TRAO ĐỔI</div>
            <textarea
              className="note-text-inp"
              value={noteContent}
              onChange={(e) => {
                setNoteContent(e.target.value);
              }}
            ></textarea>
            <div
              className="ordered-btn blue"
              onClick={(e) => {
                onPostNote();
                e.preventDefault();
              }}
            >
              Gửi
            </div>
            {noteList
              ? noteList.map((v) => {
                  return (
                    <div className="note-sim-component">
                      <div className="note-sim-date">
                        <div className="note-sim-line">line</div>
                        <div>{v.createdAt}</div>
                        <div className="note-sim-line">line</div>
                      </div>
                      {v.list.map((item) => {
                        return (
                          <div className="note-sim-item">
                            <div className="note-item-avt">
                              {item.avataUrl ? (
                                <img src={item.avataUrl} alt="avatar" />
                              ) : (
                                <div className="no-sim-avt"></div>
                              )}
                            </div>
                            <div className="note-sim-detail">
                              <div>
                                <b>{item.fullName}</b>
                              </div>
                              <div>{item.noteContent}</div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })
              : null}
            <div className="ordered-btn-container">
              {simOrder ? (
                simOrder.progressStatus == EnumSimOrderProgressStatus.Waiting ? (
                  <div
                    className="ordered-btn"
                    onClick={() => {
                      onDeleteSimOrder();
                    }}
                  >
                    Xóa đơn hàng
                  </div>
                ) : null
              ) : null}
              {simOrder ? (
                simOrder.progressStatus != EnumSimOrderProgressStatus.Error &&
                simOrder.progressStatus != EnumSimOrderProgressStatus.RecallDefectiveGoods ? (
                  <div
                    className="ordered-btn"
                    onClick={() => {
                      onConfirmReportError();
                    }}
                  >
                    Báo lỗi
                  </div>
                ) : null
              ) : null}
            </div>
          </div>
          {simOrder?.image ? (
            <div className={`bill-full-image-container ${isShowFullImage ? 'full-size' : ''}`}>
              <div
                className="btn-bill close"
                title="close"
                onClick={() => {
                  setIsShowFullImage(false);
                }}
              >
                <i className="fas fa-window-close"></i>
              </div>
              <img src={simOrder.image} alt="Image" />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
