/************************************/
/********                    ********/
/******    Common info        ********/
/******   Writen by HuyLV      ****/
/********                   ********/
/***********************************/

import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { AddressResultType } from './models';

export const useGetAddressByZipcode = () => {
  const fetch = useFetch();
  return useCallback(
    (zipcode: string) => {
      return fetch({
        url: `sccore/rest-api/common-infomation/address?zipcode=${zipcode}`,
        method: 'get',
      }) as Promise<AddressResultType>;
    },
    [fetch],
  );
};

export const useGetExchangeRate = () => {
  const fetch = useFetch();
  return useCallback(() => {
    return fetch({
      url: `sccore/rest-api/common-infomation/exchange-rate`,
      method: 'get',
    }) as Promise<number>;
  }, [fetch]);
};

export const useGetShipFeeByZipcodeAndMoney = () => {
  const fetch = useFetch();
  return useCallback(
    (zipCode: string, money: number) => {
      return fetch({
        url: `sccore/rest-api/common-infomation/ship-fee?zipCode=${zipCode}&money=${money}`,
        method: 'get',
      }) as Promise<number>;
    },
    [fetch],
  );
};

export const useGetDistanceBySourceAndDest = () => {
  const fetch = useFetch();
  return useCallback(
    (source: string, dest: number) => {
      return fetch({
        url: `sccore/rest-api/common-infomation/distance-zipcode?source=${source}&dest=${dest}`,
        method: 'get',
      }) as Promise<number>;
    },
    [fetch],
  );
};
//End of common infomation
