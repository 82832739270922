import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { PageData, SimType, SimPriceType, SimPriceDebitType } from './models';

//Sim
export const useGetMySim = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (page: number, size: number, keyword?: string) => {
      return fetch({
        url: `sccore/rest-api/sim/me?page=${page}&size=${size}${
          keyword && keyword != '' ? `&keyword=${keyword}` : ''
        }`,
        method: 'get',
      }) as Promise<PageData<SimType>>;
    },
    [fetch],
  );
};

export const useGetSimPrice = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (date: string) => {
      const d = {
        date: date,
      };
      return fetch({
        url: `sccore/rest-api/sim-price/date`,
        method: 'post',
        data: d,
      }) as Promise<SimPriceType[]>;
    },
    [fetch],
  );
};

export const useGetMySimPrice = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = useFetch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (date: string) => {
      const d = {
        date: date,
      };
      return fetch({
        url: `sccore/rest-api/sim-price/date/me`,
        method: 'post',
        data: d,
      }) as Promise<SimPriceType[]>;
    },
    [fetch],
  );
};

export const useGetSimOrderDebit = () => {
  const fetch = useFetch();
  return useCallback(
    (userId: number, fromDate: string, toDate: string) => {
      const data = {
        userId: userId,
        fromDate: fromDate,
        toDate: toDate,
      };
      return fetch({
        url: `sccore/rest-api/sim-order/debit/me`,
        method: 'post',
        data: data,
      }) as Promise<SimPriceDebitType>;
    },
    [fetch],
  );
};
