import React from 'react';
import { NavLink } from 'react-router-dom';
import { EventButton } from 'src/api/models';
import './ButtonAction.css';

interface IButtonAction {
  //Define
  // name: string;
  // icon: string;
  // actionType: EnumAction;
  // action: (...args: unknown[]) => unknown;
  // color?: string;
  // backgroundColor: string;
  // buttonClass?: string;
  button: EventButton;
  width: string;
  height: string;
}

const ButtonAction: React.FC<IButtonAction> = (props) => {
  return (
    <button
      style={{
        width: props.width,
        height: props.height,
        alignItems: props.button.align,
      }}
      className={`action-button ${props.button.buttonClass}`}
      onClick={props.button.action}
    >
      <span className="material-icons">{props.button.icon}</span>
      {props.button.name}
    </button>
  );
};

export default ButtonAction;
