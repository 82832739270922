import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { EnumSimStatus, SimType } from 'src/api/models';
import { useGetMySim } from 'src/api/simPriceApi';
import useQuery from 'src/hooks/useQuery';
import './ActiveSimList.css';

const ActiveSimList: React.FC = () => {
  //Value
  const history = useHistory();
  const query = useQuery();
  const page = query.get('page') ? Number(query.get('page')) : 1;
  const size = query.get('size') ? Number(query.get('size')) : 20;
  const keyword = query.get('keyword') ? query.get('keyword') : '';

  //State
  const [simList, setSimList] = useState<SimType[]>(null);
  const [maxPage, setMaxPage] = useState(null);
  const [count, setCount] = useState(null);
  const [elmOption, setElmOption] = useState<JSX.Element[]>(null);

  //Function
  const getMySim = useGetMySim();

  const list = () => {
    return (
      <div className="row p-0 m-0 justify-content-center">
        <div className="col-lg-5 p-1 m-0 ordered-list-wrapper">
          <div className="ordered-list-title">DANH SÁCH SIM ĐANG HOẠT ĐỘNG</div>
          <div className="ordered-list-header">
            <div className="ordered-list-paging">
              <div
                className="order-item-btn minus"
                onClick={() => {
                  if (page > 1) {
                    const url = `active-sim-list?page=${
                      page - 1
                    }&size=${size}&keyword=${keyword}`;
                    history.push(url);
                  }
                }}
              >
                <i className="fas fa-angle-left"></i>
              </div>
              <div>
                {count == 0 ? 0 : (page - 1) * size + 1}-
                {count > 0 ? (page * size < count ? page * size : count) : 0}/
                {count > 0 ? count : 0}
              </div>
              {elmOption ? (
                <select
                  className="select-page"
                  value={page}
                  onChange={(event) => {
                    const url = `active-sim-list?page=${Number(
                      event.target.value,
                    )}&size=${size}&keyword=${keyword}`;
                    history.push(url);
                  }}
                >
                  {elmOption}
                </select>
              ) : null}
              <div
                className="order-item-btn plus"
                onClick={() => {
                  if (page < maxPage) {
                    const url = `active-sim-list?page=${
                      page + 1
                    }&size=${size}&keyword=${keyword}`;
                    history.push(url);
                  }
                }}
              >
                <i className="fas fa-angle-right"></i>
              </div>
              <select
                className="select-size"
                value={size}
                onChange={(event) => {
                  const url = `active-sim-list?page=1&size=${Number(
                    event.target.value,
                  )}&keyword=${keyword}`;
                  history.push(url);
                }}
              >
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <div className="active-sim-keyword">
                <input
                  type="text"
                  placeholder="Bạn muốn tìm gì?"
                  value={keyword}
                  onChange={(event) => {
                    const url = `active-sim-list?page=1&size=${size}&keyword=${event.target.value}`;
                    history.push(url);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="ordered-list-pc">
            {simList
              ? simList.map((value, index) => {
                  return (
                    <div
                      className={`ordered-item-container ${index % 2 == 1 ? 'le' : 'chan'}`}
                      onClick={(event) => {
                        const url = `active-sim-list/${value.simId}`;
                        history.push(url);
                        event.preventDefault();
                      }}
                    >
                      <div className="ordered-item-info">
                        <div>Tên KH: {value.customerName}</div>
                        <div>Loại: {value.product.productName}</div>
                      </div>
                      <div className="ordered-item-status">
                        <div>
                          {value.status == EnumSimStatus.Actice ? (
                            <span style={{ color: 'blue' }}>Đang hoạt động</span>
                          ) : value.status == EnumSimStatus.Deactive ? (
                            <span style={{ color: 'yellowgreen' }}>Chưa kích hoạt</span>
                          ) : value.status == EnumSimStatus.Error ? (
                            <span style={{ color: 'red' }}>Lỗi</span>
                          ) : value.status == EnumSimStatus.StopService ? (
                            <span style={{ color: 'gray' }}>Ngừng hoạt động</span>
                          ) : null}
                        </div>
                        <div>SĐT/IMEI: {value.simPhoneNumber}</div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    );
  };

  //useEffect
  useEffect(() => {
    getMySim(page, size, keyword)
      .then((data) => {
        const temp = Math.ceil(data.count / size);
        const arr: JSX.Element[] = [];

        for (let i = 1; i <= temp; i++) {
          const elm = <option value={i}>{i}</option>;
          arr.push(elm);
        }

        setElmOption(arr);
        setSimList(data.items);
        setCount(data.count);
        setMaxPage(temp);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getMySim, keyword, page, size]);

  //Main
  return <div className="container-fluid ordered-list-container p-0 m-0 ">{list()}</div>;
};

export default ActiveSimList;
