import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useChangePassword } from 'src/api/backend-api';
import useProfile from 'src/hooks/useProfile';
import { useAddPopup } from 'src/state/application/hooks';
import './ChangePassword.css';

const Verify: React.FC = () => {
  //Value
  const profile = useProfile();
  const history = useHistory();

  //State
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [emailErrorMessage, setEmailErrorMessage] = useState(null);

  const [currentPassword, setCurrentPassword] = useState<string | undefined>(undefined);
  const [currentPasswordErrorMessage, setCurrentPasswordErrorMessage] = useState(null);

  const [password, setPassword] = useState<string | undefined>(undefined);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(null);

  const [confirmPassword, setConfirmPassword] = useState(null);
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState(null);

  const [focusInput, setFocusInput] = useState(null);

  //Function
  const addPopup = useAddPopup();
  const changePassword = useChangePassword();

  const onPressBtnChangePassword = () => {
    const isEmail = validateEmail();
    const isCurrentPassword = validateCurrentPassword();
    const isPassword = validatePassword();
    const isConfirmPassword = validateConfirmPassword();
    if (isEmail && isCurrentPassword && isPassword && isConfirmPassword) {
      changePassword(email, currentPassword, password, confirmPassword)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Đổi mật khẩu thành công',
            },
          });
          history.push(`/auth-screen/login?email=${email}`);
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          });
        });
    }
  };

  const validateEmail = () => {
    let isContinue = true;

    if (!email || email == '') {
      isContinue = false;
      setEmailErrorMessage('Chưa nhập mã email');
    } else {
      setEmailErrorMessage(null);
    }

    return isContinue;
  };

  const validateCurrentPassword = () => {
    let isContinue = true;

    if (!currentPassword || currentPassword == '') {
      isContinue = false;
      setCurrentPasswordErrorMessage('Chưa nhập mật khẩu cũ');
    } else setCurrentPasswordErrorMessage(null);

    return isContinue;
  };

  const validatePassword = () => {
    let isContinue = true;

    if (!password || password == '') {
      isContinue = false;
      setPasswordErrorMessage('Chưa nhập mật khẩu mới');
    } else setPasswordErrorMessage(null);

    return isContinue;
  };

  const validateConfirmPassword = () => {
    let isContinue = true;

    if (!confirmPassword || confirmPassword == '') {
      isContinue = false;
      setConfirmPasswordErrorMessage('Chưa xác nhận mật khẩu');
    } else if (confirmPassword !== password) {
      isContinue = false;
      setConfirmPasswordErrorMessage('Không khớp');
    } else {
      setConfirmPasswordErrorMessage(null);
    }

    return isContinue;
  };

  //TODO
  const handlePressInput = (event) => {
    const form = event.target.form;
    const index = [...form].indexOf(event.target);

    if (event.key.toLowerCase() === 'enter') {
      if (focusInput >= 1 && focusInput <= 3) {
        form.elements[index + 1].focus();
        setFocusInput(focusInput + 1);
        event.preventDefault();
      } else {
        onPressBtnChangePassword();
      }
    } else if (event.key.toLowerCase() === 'arrowdown') {
      if (focusInput >= 1 && focusInput <= 3) {
        form.elements[index + 1].focus();
        setFocusInput(focusInput + 1);
        event.preventDefault();
      }
    } else if (event.key.toLowerCase() === 'arrowup') {
      if (focusInput >= 2 && focusInput <= 4) {
        setFocusInput(focusInput - 1);
        form.elements[index - 1].focus();
        event.preventDefault();
      }
    }
  };

  //Component
  //1
  const emailInput = () => {
    return (
      <>
        <div
          className={`confirm-forgot-password-input ${focusInput == 1 ? 'focus-input' : ''} ${
            email ? 'validate-input' : ''
          }`}
        >
          <div className="confirm-forgot-password-input-title">Email</div>
          <input
            type="text"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(1);
            }}
            onBlur={() => {
              validateEmail();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 1}
            onKeyDown={handlePressInput}
          />
        </div>
        <div style={{ color: 'red', paddingLeft: 2 }}>{emailErrorMessage}</div>
      </>
    );
  };

  //2
  const currentPasswordInput = () => {
    return (
      <>
        <div
          className={`confirm-forgot-password-input ${focusInput == 2 ? 'focus-input' : ''} ${
            currentPassword ? 'validate-input' : ''
          }`}
        >
          <div className="confirm-forgot-password-input-title">Mật khẩu cũ</div>
          <input
            type="password"
            value={currentPassword}
            onChange={(event) => {
              setCurrentPassword(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(2);
            }}
            onBlur={() => {
              validateCurrentPassword();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 2}
            onKeyDown={handlePressInput}
          />
        </div>
        <div style={{ color: 'red', paddingLeft: 2 }}>{currentPasswordErrorMessage}</div>
      </>
    );
  };

  //3
  const passwordInput = () => {
    return (
      <>
        <div
          className={`confirm-forgot-password-input ${focusInput == 3 ? 'focus-input' : ''} ${
            password ? 'validate-input' : ''
          }`}
        >
          <div className="confirm-forgot-password-input-title">Mật khẩu mới</div>
          <input
            type="password"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(3);
            }}
            onBlur={() => {
              validatePassword();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 2}
            onKeyDown={handlePressInput}
          />
        </div>
        <div style={{ color: 'red', paddingLeft: 2 }}>{passwordErrorMessage}</div>
      </>
    );
  };

  //4
  const confirmPasswordInput = () => {
    return (
      <>
        <div
          className={`confirm-forgot-password-input ${focusInput == 4 ? 'focus-input' : ''} ${
            confirmPassword ? 'validate-input' : ''
          }`}
        >
          <div className="confirm-forgot-password-input-title">Xác nhận mật khẩu</div>
          <input
            type="password"
            value={confirmPassword}
            onChange={(event) => {
              setConfirmPassword(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(4);
            }}
            onBlur={() => {
              validateConfirmPassword();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 4}
            onKeyDown={handlePressInput}
          />
        </div>
        <div style={{ color: 'red', paddingLeft: 2 }}>{confirmPasswordErrorMessage}</div>
      </>
    );
  };

  return profile ? (
    <Redirect to="/" />
  ) : (
    <div className="verify-form">
      <div className="verify-title">ĐỔI MẬT KHẨU</div>
      <form>
        {emailInput()}
        {currentPasswordInput()}
        {passwordInput()}
        {confirmPasswordInput()}
      </form>
      <button
        className="verify-btn"
        onClick={() => {
          onPressBtnChangePassword();
        }}
      ></button>
      <div className="verify-confirm-text-container">
        J.V.S 株式会社 Telecom-Express-Order-Laptop-Reuse <br /> Website:{' '}
        <a href="https://jvscorp.jp">https://jvscorp.jp</a> <br />
        Email: support@jvscorp.jp <br /> Phone: <a href="tel:070-8332-6668">07083326668</a>
        <br />
        Android & Ios App: JVS Connect
      </div>
    </div>
  );
};

export default Verify;
