import React, { useState, useEffect } from 'react';
import { useGetAgencySimOrderList } from 'src/api/backend-api';
import { EnumSimOrderProgressStatus, PageData, SimOrderType } from 'src/api/models';
import './OrderedList.css';
import useQuery from 'src/hooks/useQuery';
import { useHistory } from 'react-router';

const OrderedList: React.FC = () => {
  //Value
  const history = useHistory();
  const query = useQuery();
  const page = query.get('page') ? Number(query.get('page')) : 1;
  const size = query.get('size') ? Number(query.get('size')) : 20;

  //State
  const [orderedList, setOrderedList] = useState<PageData<SimOrderType>>(null);
  const [maxPage, setMaxPage] = useState(null);
  const [count, setCount] = useState(null);
  const [elmOption, setElmOption] = useState<JSX.Element[]>(null);
  //End of state

  //Function
  const getMyOrderedList = useGetAgencySimOrderList();

  //useEffect
  useEffect(() => {
    getMyOrderedList(page, size)
      .then((data) => {
        const temp = Math.ceil(data.count / size);
        const arr: JSX.Element[] = [];

        for (let i = 1; i <= temp; i++) {
          const elm = <option value={i}>{i}</option>;
          arr.push(elm);
        }

        setElmOption(arr);
        setOrderedList(data);
        setCount(data.count);
        setMaxPage(temp);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getMyOrderedList, page, size]);
  //End of useEffect

  //Component
  const list = () => {
    return (
      <div className="row p-0 m-0 justify-content-center">
        <div className="col-lg-5 p-1 m-0 ordered-list-wrapper">
          <div className="ordered-list-title">DANH SÁCH ĐƠN HÀNG</div>
          <div className="ordered-list-header">
            <div className="ordered-list-paging">
              <div
                className="order-item-btn minus"
                onClick={() => {
                  if (page > 1) {
                    const url = `ordered-list?page=${page - 1}&size=${size}`;
                    history.push(url);
                  }
                }}
              >
                <i className="fas fa-angle-left"></i>
              </div>
              <div>
                {count == 0 ? 0 : (page - 1) * size + 1}
                {count > 0 ? '-' : null}
                {count > 0 ? (page * size < count ? page * size : count) : null}
                {count > 0 ? '/' : null}
                {count > 0 ? count : null}
              </div>
              {elmOption ? (
                <select
                  className="select-page"
                  value={page}
                  onChange={(event) => {
                    const url = `ordered-list?page=${event.target.value}&size=${size}`;
                    history.push(url);
                  }}
                >
                  {elmOption}
                </select>
              ) : null}
              <div
                className="order-item-btn plus"
                onClick={() => {
                  if (page < maxPage) {
                    const url = `ordered-list?page=${page + 1}&size=${size}`;
                    history.push(url);
                  }
                }}
              >
                <i className="fas fa-angle-right"></i>
              </div>
              <select
                className="select-size"
                value={size}
                onChange={(event) => {
                  const url = `ordered-list?page=1&size=${event.target.value}`;
                  history.push(url);
                }}
              >
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
          <div className="ordered-list-pc">
            {orderedList
              ? orderedList.items.map((value, index) => {
                  return (
                    <div
                      className={`ordered-item-container ${index % 2 == 1 ? 'le' : 'chan'}`}
                      onClick={(event) => {
                        const url = `ordered-list/${value.simOrderId}`;
                        history.push(url);
                        event.preventDefault();
                      }}
                    >
                      <div className="ordered-item-info">
                        <div>Ngày: {value.createdAt}</div>
                        <div>Người nhận: {value.fullname}</div>
                      </div>
                      <div className="ordered-item-status">
                        <div>{value.trackingCode}</div>
                        <div>
                          {value.progressStatus == EnumSimOrderProgressStatus.Waiting ? (
                            <span style={{ color: 'gray', marginLeft: 10 }}>Chưa gửi hàng</span>
                          ) : value.progressStatus == EnumSimOrderProgressStatus.Sent ? (
                            <span style={{ color: 'blue', marginLeft: 10 }}>Đã gửi hàng</span>
                          ) : value.progressStatus == EnumSimOrderProgressStatus.Error ? (
                            <span style={{ color: 'red', marginLeft: 10 }}>Lỗi</span>
                          ) : value.progressStatus ==
                            EnumSimOrderProgressStatus.RecallDefectiveGoods ? (
                            <span style={{ color: 'yellowgreen', marginLeft: 10 }}>
                              Thu hồi
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    );
  };

  //Main
  return <div className="container-fluid ordered-list-container p-0 m-0 ">{list()}</div>;
};

export default OrderedList;
