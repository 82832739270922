import './Confirm.css';
import React from 'react';
import { useRemoveProfileInfo } from 'src/state/application/hooks';
import Images from 'src/assets/img';

const Login: React.FC = () => {
  const removeProfileInfo = useRemoveProfileInfo();

  const onPressBtnLogOut = () => {
    removeProfileInfo();
  };

  //Main
  return (
    <div className="login-form">
      <div className="login-title">Bạn chưa có quyền đăng nhập</div>
      <div className="error-auth-scr-img">
        <img src={Images.error} alt="error" />
      </div>
      <button
        className="confirm-btn"
        onClick={() => {
          onPressBtnLogOut();
        }}
      ></button>
      <div style={{ marginTop: 15 }}>Liên hệ chúng tôi để kích hoạt tài khoản</div>
      <div className="login-confirm-text-container">
        J.V.S 株式会社 Telecom-Express-Order-Laptop-Reuse <br /> Website:{' '}
        <a href="https://jvscorp.jp">https://jvscorp.jp</a> <br />
        Email: support@jvscorp.jp <br /> Phone: <a href="tel:070-8332-6668">07083326668</a>
        <br />
        Android & Ios App: JVS Connect
      </div>
    </div>
  );
};

export default Login;
