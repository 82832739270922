import './Base.css';
import React, { useEffect, useState } from 'react';
import Header from 'src/components/Header/Header';
import { NavLink, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import AddOrderScreen from './AddOrderScreen/AddOrderScreen';
import OrderedList from './OrderedList/OrderedList';
import Images from 'src/assets/img';
import ActiveSimList from './ActiveSimList/ActiveSimList';
import SimPrice from './Account/SimPrice/SimPrice';
import Account from './Account/Account';
import MonthlySubscriptionFee from './Account/MonthlySubscriptionFee/MonthlySubscriptionFee';
import Debit from './Account/Debit/Debit';
import {
  useGetIsContinue,
  useGetNotiCount,
  useSetNotiCount,
} from 'src/state/application/hooks';
import OrderDetail from './OrderedList/OrderDetail/OrderDetail';
import ActiveSimDetail from './ActiveSimList/ActiveSimDetail/ActiveSimDetail';
import Notification from './Notification/Notification';
import { useReadAllNotification } from 'src/api/notificationApi';
import DashBoardHeaderBar from './DashBoardHeaderBar/DashBoardHeaderBar';
import GuidePage from './GuidePage/GuidePage';
import GuidePageDetail from './GuidePage/GuidePageDetail/GuidePageDetail';
import SimNews from './SimNews/SimNews';
import SimNewsDetail from './SimNews/SimNewsDetail/SimNewsDetail';
import AccountBalance from './Account/AccountBalance/AccountBalance';

const Base: React.FC = () => {
  //Value
  const isContinue = useGetIsContinue();
  const notiCount = useGetNotiCount();
  const history = useHistory();

  //State
  const [isShowNav, setIsShowNav] = useState(false);
  const [isShowNoti, setIsShowNoti] = useState(false);
  const [page, setPage] = useState(1);

  //Function
  const readAllNoti = useReadAllNotification();
  const setNotiCount = useSetNotiCount();

  const onChangePage = (p: number) => {
    setPage(p);
  };

  const onHiddenPopup = () => {
    setIsShowNoti(!isShowNoti);
  };

  const clickBtnNav = () => {
    setIsShowNav(!isShowNav);
  };

  useEffect(() => {
    const handleClick = (event) => {
      const navPc = document.getElementById('header-pc');
      const btnNavBase = document.getElementById('btn-nav-base');
      if (btnNavBase) {
        if (btnNavBase.contains(event.target)) {
          console.log('click-btn-nav');
        } else if (!navPc.contains(event.target)) {
          setIsShowNav(false);
        }
      }
    };

    document.addEventListener('click', handleClick, false);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  useEffect(() => {
    const handleClick = (event) => {
      const notiPopup = document.getElementById('noti-popup');
      const btnShowNoti = document.getElementById('btn-show-noti');
      if (btnShowNoti && notiPopup) {
        if (btnShowNoti.contains(event.target)) {
          console.log('click-btn-show-noti');
        } else if (!notiPopup.contains(event.target)) {
          setIsShowNoti(false);
        }
      }
    };

    document.addEventListener('click', handleClick, false);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  //Main
  return !isContinue ? (
    <Redirect to="/auth-screen" />
  ) : (
    <div className="base-container">
      <div className="base-main">
        <Switch key="base-switch-main">
          <Route path="/ordered-list/:simOrderId">
            <OrderDetail />
          </Route>
          <Route path="/ordered-list">
            <OrderedList />
          </Route>
          <Route path="/active-sim-list/:simId" exact>
            <ActiveSimDetail />
          </Route>
          <Route path="/active-sim-list" exact>
            <ActiveSimList />
          </Route>
          <Route path="/account" exact>
            <Account />
          </Route>
          <Route path="/account/debit" exact>
            <Debit />
          </Route>
          <Route path="/account/account-balance" exact>
            <AccountBalance />
          </Route>
          <Route path="/account/monthly-subscription-fee" exact>
            <MonthlySubscriptionFee />
          </Route>
          <Route path="/account/sim-price" exact>
            <SimPrice />
          </Route>
          <Route path="/notification" exact>
            <Notification />
          </Route>
          <Route path="/guide-page/:guidePageKey">
            <GuidePageDetail />
          </Route>
          <Route path="/guide-page" exact>
            <GuidePage />
          </Route>
          <Route path="/sim-news/:simNewId">
            <SimNewsDetail />
          </Route>
          <Route path="/sim-news" exact>
            <SimNews />
          </Route>
          <Route path="/" exact>
            <AddOrderScreen />
          </Route>
        </Switch>
      </div>
      <div className="base-head d-none d-lg-flex ">
        <div className="d-none d-lg-flex">JAPAN VIETNAM SERVICE</div>
        <div className="base-head-right">
          <div
            className="base-head-icon"
            title="Hướng dẫn"
            onClick={() => {
              const url = 'guide-page';
              history.push(url);
            }}
          >
            <i className="fas fa-lightbulb" style={{ fontSize: 20 }}></i>
          </div>
          <div
            className="base-head-icon"
            title="Khuyến mại"
            onClick={() => {
              const url = 'sim-news';
              history.push(url);
            }}
          >
            <i className="fas fa-ad" style={{ fontSize: 20 }}></i>
          </div>
          <div className="base-head-icon noti" title="Thông báo">
            <i
              id="btn-show-noti"
              className="fas fa-bell"
              style={{ fontSize: 20 }}
              onClick={() => {
                readAllNoti()
                  .then(() => {
                    setNotiCount(0);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
                setIsShowNoti(!isShowNoti);
              }}
            ></i>
            {notiCount && notiCount > 0 ? <div className="noti-count">{notiCount}</div> : null}
            {isShowNoti ? (
              <DashBoardHeaderBar
                page={page}
                onChangePage={onChangePage}
                onHiddenPopup={onHiddenPopup}
              />
            ) : null}
          </div>
          <NavLink to={'/'}>
            <img src={Images.logo} alt="" />
          </NavLink>
        </div>
      </div>
      <div className="btn-show-nav" onClick={clickBtnNav} id="btn-nav-base">
        <i className="fas fa-bars"></i>
      </div>
      <div className={`base-nav-container ${isShowNav ? 'showNav' : ''}`}>
        <Header functionShowNav={clickBtnNav} />
      </div>
    </div>
  );
};

export default Base;
