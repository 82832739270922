import { createReducer, nanoid } from '@reduxjs/toolkit';
import { RealmType } from 'src/api/models';
import {
  addPopup,
  PopupContent,
  removePopup,
  login,
  logout,
  setHightlightProduct,
  reloadTable,
  setIsContinue,
  setNotiCount,
} from './actions';

type PopupList = Array<{
  key: string;
  show: boolean;
  content: PopupContent;
  removeAfterMs: number | null;
}>;

export type ProfileInfo = {
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  userId: number;
  birthday: string;
  email: string;
  fullName: string;
  loginName: string;
  telephone: string;
  address: string;
  avataUrl: string | null;
};

export type Profile = {
  accessToken: string;
  refreshToken: string;
  info?: ProfileInfo;
};

export interface ApplicationState {
  popupList: PopupList;
  profile: Profile;
  reloadFlag: boolean;
  hightlightProduct: number | null;
  listRealm: RealmType[] | null;
  editRowIndex: number | null;
  isContinue?: boolean;
  notiCount?: number;
}

export const initialState: ApplicationState = {
  popupList: [],
  profile: undefined,
  reloadFlag: false,
  hightlightProduct: null,
  listRealm: undefined,
  editRowIndex: null,
  isContinue: false,
  notiCount: 0,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(removePopup, (state, { payload: { key, type } }) => {
      //type: all, context, notification
      if (type === 'all') {
        state.popupList.map((_p, index: number) => {
          if (_p.show === false) {
            state.popupList.splice(index, 1);
          }
        });
      }
      state.popupList.forEach((p) => {
        if (type === 'notification' || type === 'confirm') {
          if (p.key === key) {
            p.show = false;
          }
        } else if (
          ('view' in p.content || 'confirm' in p.content || 'context' in p.content) &&
          type !== 'notification'
        ) {
          p.show = false;
        }
      });
    })
    .addCase(addPopup, (state, { payload: { content, key, removeAfterMs = 1000 } }) => {
      state.popupList = (
        key ? state.popupList.filter((popup) => popup.key !== key) : state.popupList
      ).concat([
        {
          key: key || nanoid(),
          show: true,
          content,
          removeAfterMs,
        },
      ]);
    })
    .addCase(login, (state, { payload: { profile } }) => {
      state.profile = profile;
    })
    .addCase(logout, (state) => {
      state.profile = undefined;
      state.isContinue = false;
    })
    .addCase(reloadTable, (state) => {
      state.reloadFlag = !state.reloadFlag;
    })
    .addCase(setHightlightProduct, (state, { payload: { index } }) => {
      state.hightlightProduct = index;
    })
    .addCase(setIsContinue, (state, { payload: { isContinue } }) => {
      state.isContinue = isContinue;
    })
    .addCase(setNotiCount, (state, { payload: { notiCount } }) => {
      state.notiCount = notiCount;
    }),
);
