import React, { useState, useEffect } from 'react';
import { useGetIdProduct } from 'src/api/backend-api';
import { ProductType } from 'src/api/models';
import './SimDetailItem.css';

interface ISimDetailItem {
  productId: number;
  simPhoneNumber?: number;
  price: number;
}

const SimDetailItem: React.FC<ISimDetailItem> = (props) => {
  //State
  const [data, setData] = useState<ProductType>(null);

  const getProductById = useGetIdProduct();

  useEffect(() => {
    getProductById(props.productId)
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getProductById, props.productId]);

  //Main
  return data ? (
    <div className="sim-detail-item-container">
      <div className="sim-detail-item-image">
        <img src={data.thumbAvatar} alt="avatar" />
      </div>
      <div className="sim-detail-item-detail">
        <div className="sim-detail-item-title">{data.productName}</div>
        <div className="sim-detail-item-des">Giá: {props.price}</div>
        <div>SĐT/IMEI: {props.simPhoneNumber ? props.simPhoneNumber : 'Chưa có SĐT/IMEI'}</div>
      </div>
    </div>
  ) : null;
};

export default SimDetailItem;
