import React from 'react';
import { useHistory } from 'react-router-dom';
import useProfile from 'src/hooks/useProfile';
import { useRemoveProfileInfo } from 'src/state/application/hooks';
import './Account.css';

const Account: React.FC = () => {
  //Value
  const profile = useProfile();
  const history = useHistory();

  //Function
  const removeProfileInfo = useRemoveProfileInfo();
  const onLogout = () => {
    const url = '/';
    removeProfileInfo();
    history.push(url);
  };

  //Main
  return (
    <div className="account-container">
      <div className="account-detail">
        <div className="account-detail-left">
          <div className="account-detail-title">THÔNG TIN TÀI KHOẢN</div>
          <div className="account-detail-component btn">
            <div>
              <span style={{ fontWeight: 500 }}>Họ tên</span>: {profile.info.fullName}
            </div>
          </div>
          <div className="account-detail-component btn">
            <div>
              <span style={{ fontWeight: 500 }}>Email</span>: {profile.info.email}
            </div>
          </div>
        </div>
        <div className="account-detail-right">
          {/* <div
            className="account-btn"
            onClick={() => {
              const url = '/account/debit';
              history.push(url);
            }}
          >
            <div>
              <i className="fas fa-coins" style={{ color: '#0090ed', width: 25 }}></i>
              <span style={{ marginLeft: 10 }}>Công nợ</span>
            </div>
            <div>
              <i className="fas fa-angle-right" style={{ color: 'gray' }}></i>
            </div>
          </div> */}
          <div
            className="account-btn"
            onClick={() => {
              const url = '/account/monthly-subscription-fee';
              history.push(url);
            }}
          >
            <div>
              <i
                className="fas fa-comments-dollar"
                style={{ color: 'yellowgreen', width: 25 }}
              ></i>
              <span style={{ marginLeft: 10 }}>Cước tháng</span>
            </div>
            <div>
              <i className="fas fa-angle-right" style={{ color: 'gray' }}></i>
            </div>
          </div>
          <div
            className="account-btn"
            onClick={() => {
              const url = '/account/account-balance';
              history.push(url);
            }}
          >
            <div>
              <i className="fas fa-wallet" style={{ color: 'yellowgreen', width: 25 }}></i>
              <span style={{ marginLeft: 10 }}>Tài khoản tiền</span>
            </div>
            <div>
              <i className="fas fa-angle-right" style={{ color: 'gray' }}></i>
            </div>
          </div>
          <div
            className="account-btn"
            onClick={() => {
              const url = '/account/sim-price';
              history.push(url);
            }}
          >
            <div>
              <i className="fas fa-dollar-sign" style={{ width: 25, color: '#18a7c6' }}></i>
              <span style={{ marginLeft: 10 }}>Bảng giá</span>
            </div>
            <div>
              <i className="fas fa-angle-right" style={{ color: 'gray' }}></i>
            </div>
          </div>
          <div className="account-btn" onClick={onLogout}>
            <div>
              <i className="fas fa-sign-out-alt" style={{ color: '#ed4d2d', width: 25 }}></i>
              <span style={{ marginLeft: 10 }}>Đăng xuất</span>
            </div>
            <div>
              <i className="fas fa-angle-right" style={{ color: 'gray' }}></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
