import React, { useEffect, useState } from 'react';
import { SimType } from 'src/api/models';
import { useGetMonthlySubscriptionFee } from 'src/api/simApi';
import useProfile from 'src/hooks/useProfile';
import { useAddPopup } from 'src/state/application/hooks';
import './MonthlySubscriptionFee.css';

const MonthlySubscriptionFee: React.FC = () => {
  //Value
  const profile = useProfile();

  //State
  const [totalMoney, setTotalMoney] = useState(0);
  const [sims, setSims] = useState<SimType[]>(null);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  const [elmThang, setElmThang] = useState([]);
  const [elmNam, setElmNam] = useState([]);

  //Function
  const addPopup = useAddPopup();
  const getMonthlySubscriptionFee = useGetMonthlySubscriptionFee();

  const onChangeToMonthType = (mm: number, yy: number) => {
    const numberOfMiliSecond = 86400000;
    const fDate = `1/${mm}/${yy}`;

    if (mm < 12) {
      const temp = new Date(`${yy}/${Number(mm) + 1}/1`);
      const to_date = new Date(temp.getTime() - 1 * numberOfMiliSecond);

      const dd2 = to_date.getDate();
      const mm2 = to_date.getMonth() + 1;
      const yy2 = to_date.getFullYear();

      const tDate = `${dd2}/${mm2}/${yy2}`;

      setFromDate(fDate);
      setToDate(tDate);
    } else {
      const tDate = `31/12/${year}`;
      setFromDate(fDate);
      setToDate(tDate);
    }
  };

  const onShowDebit = () => {
    if (profile) {
      getMonthlySubscriptionFee(profile.info.userId, fromDate, toDate)
        .then((data) => {
          addPopup({
            txn: {
              success: true,
              summary: 'Lấy thông tin thành công!',
            },
          });
          setTotalMoney(data.totalMoney);
          setSims(data.sims);
          console.log(data);
        })
        .catch((error) => {
          console.log('error debit');
          console.log(error);
        });
    }
  };
  //End of function

  useEffect(() => {
    const numberOfMiliSecond = 86400000;
    let monthTemp;

    const date = new Date();
    const mm1 = date.getMonth() + 1;
    const yy1 = date.getFullYear();

    switch (mm1) {
      case 1:
        monthTemp = 1;
        break;
      case 2:
        monthTemp = 2;
        break;
      case 3:
        monthTemp = 3;
        break;
      case 4:
        monthTemp = 4;
        break;
      case 5:
        monthTemp = 5;
        break;
      case 6:
        monthTemp = 6;
        break;
      case 7:
        monthTemp = 7;
        break;
      case 8:
        monthTemp = 8;
        break;
      case 9:
        monthTemp = 9;
        break;
      case 10:
        monthTemp = 10;
        break;
      case 11:
        monthTemp = 11;
        break;
      case 12:
        monthTemp = 12;
        break;
    }

    const fDate = `1/${mm1}/${yy1}`;

    if (mm1 < 12) {
      const tempDate = `${yy1}/${mm1 + 1}/1`;
      const tTemp = new Date(tempDate);

      const t = new Date(tTemp.getTime() - 1 * numberOfMiliSecond);

      const dd2 = t.getDate();
      const tDate = `${dd2}/${mm1}/${yy1}`;
      setToDate(tDate);

      getMonthlySubscriptionFee(profile.info.userId, fDate, tDate)
        .then((data) => {
          setTotalMoney(data.totalMoney);
          setSims(data.sims);
        })
        .catch((error) => {
          console.log('error debit');
          console.log(error);
        });
    } else {
      const tDate = `31/12/${yy1}`;
      setToDate(tDate);
      getMonthlySubscriptionFee(profile.info.userId, fDate, tDate)
        .then((data) => {
          setTotalMoney(data.totalMoney);
          setSims(data.sims);
        })
        .catch((error) => {
          console.log('error debit');
          console.log(error);
        });
    }

    setFromDate(fDate);
    setYear(yy1);
    setMonth(monthTemp);
  }, [getMonthlySubscriptionFee, profile.info.userId]);

  useEffect(() => {
    const eTuan = [],
      eThang = [],
      eQuy = [],
      eNam = [];
    for (let i = 2021; i < 2030; i++) {
      const elm = <option value={i}>{i}</option>;
      eNam.push(elm);
    }
    for (let i = 1; i <= 52; i++) {
      const elm = <option value={i}>{i}</option>;
      eTuan.push(elm);
    }
    for (let i = 1; i <= 4; i++) {
      const elm = <option value={i}>{i}</option>;
      eQuy.push(elm);
    }
    for (let i = 1; i <= 12; i++) {
      const elm = <option value={i}>{i}</option>;
      eThang.push(elm);
    }

    setElmNam(eNam);
    setElmThang(eThang);
  }, []);
  //End of useEffect

  //Main
  return (
    <div className="debit-wrapper">
      <div className="account-detail-title">
        CƯỚC THÁNG {month}/{year}
      </div>
      <div className="debit-container">
        <div className="debit-date-container">
          <div className="debit-titlle">Từ ngày</div>
          <div className="debit-input-container">
            <div className="debit-date">{fromDate}</div>
          </div>
          <div className="debit-titlle">Đến ngày</div>
          <div className="debit-input-container">
            <div className="debit-date">{toDate}</div>
          </div>
          <div className="debit-slide-from-date">
            <div className="debit-select-component">
              <span style={{ marginRight: 10 }}>Tháng</span>
              <select
                value={month}
                onChange={(event) => {
                  setMonth(event.target.value);
                  onChangeToMonthType(Number(event.target.value), year);
                }}
              >
                {elmThang.length > 0 ? elmThang : null}
              </select>
            </div>
            <div className="debit-select-component">
              <span style={{ marginRight: 10 }}>Năm</span>
              <select
                value={year}
                onChange={(event) => {
                  setYear(event.target.value);
                  onChangeToMonthType(month, Number(event.target.value));
                }}
              >
                {elmNam.length > 0 ? elmNam : null}
              </select>
            </div>
          </div>
          <div className="debit-btn" onClick={onShowDebit}>
            Xem
          </div>
        </div>
        <div className="debit-detail">
          <div className="debit-detail-wrapper">
            <div className="debit-detail-component">
              Tổng cước cần đóng: <span style={{ color: 'orange' }}>{totalMoney}¥</span>
            </div>
          </div>
          <div className="account-detail-title">Danh sách sim đến kì đóng cước</div>
          <table className="account-debit-table">
            <tr>
              <th>STT</th>
              <th>Loại sim</th>
              <th>Tên KH</th>
              <th>Cước</th>
              <th>SĐT</th>
            </tr>
            {sims
              ? sims.map((value, index) => {
                  return (
                    <tr className={`${index % 2 == 1 ? 'le' : 'chan'}`}>
                      <td>{index + 1}</td>
                      <td>{value.product.productName}</td>
                      <td>{value.customerName}</td>
                      <td>{value.monthlySubscriptionFee}¥</td>
                      <td>{value.simPhoneNumber}</td>
                    </tr>
                  );
                })
              : null}
          </table>
        </div>
      </div>
    </div>
  );
};

export default MonthlySubscriptionFee;
