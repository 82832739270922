import React, { useEffect, useState } from 'react';
import {
  useGetAgencyAccountBalance,
  useGetMyAccountBalanceHistory,
} from 'src/api/accountBalanceApi';
import { AccountBalanceType } from 'src/api/models';
import Calendar, { EnumCalendarAlign, EnumCalendarPos } from 'src/components/Calendar/Calendar';
import useProfile from 'src/hooks/useProfile';
import { useAddPopup } from 'src/state/application/hooks';
import './AccountBalance.css';

enum EnumDay {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

enum EnumDateType {
  Tuan = 1,
  Thang = 2,
  Quy = 3,
  Nam = 4,
}

const AccountBalance: React.FC = () => {
  //Value
  const profile = useProfile();

  //State
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [keyword, setKeyword] = useState('');

  const [moneyAmount, setMoneyAmount] = useState(0);
  const [accountBalanceHistoryList, setAccountBalanceHistoryList] = useState<
    AccountBalanceType[]
  >([]);

  const [week, setWeek] = useState(null);
  const [month, setMonth] = useState(null);
  const [quarter, setQuarter] = useState(null);
  const [year, setYear] = useState(null);

  const [elmTuan, setElmTuan] = useState([]);
  const [elmThang, setElmThang] = useState([]);
  const [elmQuy, setElmQuy] = useState([]);
  const [elmNam, setElmNam] = useState([]);

  const [dateType, setDateType] = useState<EnumDateType>(EnumDateType.Thang);
  const [focusInput, setFocusInput] = useState(null);
  //End of state

  //Function
  const addPopup = useAddPopup();
  const getAccountBalanceHistory = useGetMyAccountBalanceHistory();
  const getAccountBalance = useGetAgencyAccountBalance();

  const onChangeToMonthType = (mm: number, yy: number) => {
    const numberOfMiliSecond = 86400000;
    const fDate = `1/${mm}/${yy}`;

    if (mm < 12) {
      const temp = new Date(`${yy}/${Number(mm) + 1}/1`);
      const to_date = new Date(temp.getTime() - 1 * numberOfMiliSecond);

      const dd2 = to_date.getDate();
      const mm2 = to_date.getMonth() + 1;
      const yy2 = to_date.getFullYear();

      const tDate = `${dd2}/${mm2}/${yy2}`;

      setFromDate(fDate);
      setToDate(tDate);
    } else {
      const tDate = `31/12/${year}`;
      setFromDate(fDate);
      setToDate(tDate);
    }
  };

  const onChangeToQuarterType = (qq: number, yy: number) => {
    const numberOfMiliSecond = 86400000;
    const fDate = `1/${qq * 3 - 2}/${yy}`;

    if (qq < 4) {
      const temp = new Date(`${yy}/${qq * 3 + 1}/1`);
      const to_date = new Date(temp.getTime() - 1 * numberOfMiliSecond);

      const dd2 = to_date.getDate();
      const mm2 = to_date.getMonth() + 1;
      const yy2 = to_date.getFullYear();

      const tDate = `${dd2}/${mm2}/${yy2}`;

      setFromDate(fDate);
      setToDate(tDate);
    } else {
      const tDate = `31/12/${year}`;
      setFromDate(fDate);
      setToDate(tDate);
    }
  };

  const onChantoYearType = (yy: number) => {
    const fDate = `1/1/${yy}`;
    const tDate = `31/12/${yy}`;

    setFromDate(fDate);
    setToDate(tDate);
  };

  const onChangeDateType = (dType: EnumDateType) => {
    if (dType == EnumDateType.Tuan) {
      onChangeToWeekType(week, year);
    } else if (dType == EnumDateType.Thang) {
      onChangeToMonthType(month, year);
    } else if (dType == EnumDateType.Quy) {
      onChangeToQuarterType(quarter, year);
    } else if (dType == EnumDateType.Nam) {
      onChantoYearType(year);
    }
  };

  const onChangeToWeekType = (ww: number, yy: number) => {
    let count: number;
    const numberOfMiliSecond = 86400000;
    const a = new Date(`${yy}/1/1`);
    const toDay = new Date();

    const day = toDay.getDay();

    switch (day) {
      case EnumDay.Monday:
        count = 0;
        break;
      case EnumDay.Tuesday:
        count = 1;
        break;
      case EnumDay.Wednesday:
        count = 2;
        break;
      case EnumDay.Thursday:
        count = 3;
        break;
      case EnumDay.Friday:
        count = 4;
        break;
      case EnumDay.Saturday:
        count = 5;
        break;
      case EnumDay.Sunday:
        count = 6;
        break;
    }

    const b = new Date(toDay.getTime() - count * numberOfMiliSecond);
    const numberOfWeek = Math.floor((b.getTime() - a.getTime()) / numberOfMiliSecond / 7);
    const c = new Date(b.getTime() - numberOfWeek * 7 * numberOfMiliSecond);

    const fDate = new Date(c.getTime() + (ww - 1) * 7 * numberOfMiliSecond);
    const tDate = new Date(fDate.getTime() + 6 * numberOfMiliSecond);
    const dd1 = fDate.getDate();
    const mm1 = fDate.getMonth() + 1;
    const yy1 = fDate.getFullYear();
    const dd2 = tDate.getDate();
    const mm2 = tDate.getMonth() + 1;
    const yy2 = tDate.getFullYear();

    const temp1 = `${dd1}/${mm1}/${yy1}`;
    const temp2 = `${dd2}/${mm2}/${yy2}`;
    setFromDate(temp1);
    setToDate(temp2);
  };

  const onChangeFromDate = (fDate: string) => {
    setFromDate(fDate);
  };

  const onChangeToDate = (fDate: string) => {
    setToDate(fDate);
  };

  const onShowDebit = () => {
    getAccountBalanceHistory(keyword, 1, 9999, fromDate, toDate)
      .then((r) => {
        getAccountBalance()
          .then((data) => {
            setMoneyAmount(Number(data));
            addPopup({
              txn: {
                success: true,
                summary: 'Lấy thông tin thành công',
              },
            });
          })
          .catch((e) => {
            console.log(e);
          });
        setAccountBalanceHistoryList(r.items);
      })
      .catch((error) => {
        console.log('error debit');
        console.log(error);
      });
  };
  //End of function

  //Component
  const keywordInput = () => {
    return (
      <div className="add-order-input-wrapper account-balance">
        <div
          className={`add-order-input ${focusInput == 1 ? 'focus-input' : ''} ${
            keyword ? 'validate-input' : ''
          }`}
        >
          <div className="add-order-input-title">Tìm kiếm</div>
          <input
            type="text"
            value={keyword}
            onChange={(event) => {
              setKeyword(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(1);
            }}
            onBlur={() => {
              setFocusInput(null);
            }}
            autoFocus={focusInput == 1}
            placeholder={'VD: Nguyen Van A'}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    const numberOfMiliSecond = 86400000;
    let count: number;
    let monthTemp, quarterTemp;
    let tDate;

    const date = new Date();
    const mm1 = date.getMonth() + 1;
    const yy1 = date.getFullYear();
    const day = date.getDay();

    switch (day) {
      case EnumDay.Monday:
        count = 0;
        break;
      case EnumDay.Tuesday:
        count = 1;
        break;
      case EnumDay.Wednesday:
        count = 2;
        break;
      case EnumDay.Thursday:
        count = 3;
        break;
      case EnumDay.Friday:
        count = 4;
        break;
      case EnumDay.Saturday:
        count = 5;
        break;
      case EnumDay.Sunday:
        count = 6;
        break;
    }

    switch (mm1) {
      case 1:
      case 2:
      case 3:
        quarterTemp = 1;
        break;
      case 4:
      case 5:
      case 6:
        quarterTemp = 2;
        break;
      case 7:
      case 8:
      case 9:
        quarterTemp = 3;
        break;
      case 10:
      case 11:
      case 12:
        quarterTemp = 4;
        break;
    }

    switch (mm1) {
      case 1:
        monthTemp = 1;
        quarterTemp = 1;
        break;
      case 2:
        monthTemp = 2;
        quarterTemp = 1;
        break;
      case 3:
        monthTemp = 3;
        quarterTemp = 1;
        break;
      case 4:
        monthTemp = 4;
        quarterTemp = 2;
        break;
      case 5:
        monthTemp = 5;
        quarterTemp = 2;
        break;
      case 6:
        monthTemp = 6;
        quarterTemp = 2;
        break;
      case 7:
        monthTemp = 7;
        quarterTemp = 3;
        break;
      case 8:
        monthTemp = 8;
        quarterTemp = 3;
        break;
      case 9:
        monthTemp = 9;
        quarterTemp = 3;
        break;
      case 10:
        monthTemp = 10;
        quarterTemp = 4;
        break;
      case 11:
        monthTemp = 11;
        quarterTemp = 4;
        break;
      case 12:
        monthTemp = 12;
        quarterTemp = 4;
        break;
    }

    const a = new Date(`${yy1}/1/1`);

    const fDay = new Date(date.getTime() - count * numberOfMiliSecond);
    const numberOfWeek = Math.floor((fDay.getTime() - a.getTime()) / numberOfMiliSecond / 7);

    const mm2 = fDay.getMonth() + 1;
    const yy2 = fDay.getFullYear();

    if (mm2 < 12) {
      const temp = new Date(`${yy2}/${Number(mm2) + 1}/1`);
      const to_date = new Date(temp.getTime() - 1 * numberOfMiliSecond);

      const dd1 = to_date.getDate();
      const mm1 = to_date.getMonth() + 1;
      const yy1 = to_date.getFullYear();

      tDate = `${dd1}/${mm1}/${yy1}`;
      setToDate(tDate);
    } else {
      const tDate = `31/12/${yy1}`;
      setToDate(tDate);
    }

    const fTemp = `1/${mm2}/${yy2}`;
    setFromDate(fTemp);
    setWeek(numberOfWeek + 1);
    setYear(yy1);
    setMonth(monthTemp);
    setQuarter(quarterTemp);

    getAccountBalanceHistory('', 1, 9999, fTemp, tDate)
      .then((data) => {
        setAccountBalanceHistoryList(data.items);
      })
      .catch((error) => {
        console.log('error debit');
        console.log(error);
      });
  }, [getAccountBalanceHistory, profile]);

  useEffect(() => {
    getAccountBalance()
      .then((data) => {
        setMoneyAmount(Number(data));
        addPopup({
          txn: {
            success: true,
            summary: 'Lấy thông tin thành công',
          },
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }, [addPopup, getAccountBalance]);

  useEffect(() => {
    const eTuan = [],
      eThang = [],
      eQuy = [],
      eNam = [];
    for (let i = 2021; i < 2030; i++) {
      const elm = <option value={i}>{i}</option>;
      eNam.push(elm);
    }
    for (let i = 1; i <= 52; i++) {
      const elm = <option value={i}>{i}</option>;
      eTuan.push(elm);
    }
    for (let i = 1; i <= 4; i++) {
      const elm = <option value={i}>{i}</option>;
      eQuy.push(elm);
    }
    for (let i = 1; i <= 12; i++) {
      const elm = <option value={i}>{i}</option>;
      eThang.push(elm);
    }

    setElmNam(eNam);
    setElmTuan(eTuan);
    setElmQuy(eQuy);
    setElmThang(eThang);
  }, []);
  //End of useEffect

  //Main
  return (
    <div className="debit-wrapper">
      <div className="account-detail-title">CHI TIẾT TÀI KHOẢN</div>
      <div className="debit-container">
        <div className="debit-date-container">
          <div className="debit-titlle">Từ ngày</div>
          <div className="debit-input-container">
            <div className="debit-date">{fromDate}</div>
            <Calendar
              align={EnumCalendarAlign.right}
              pos={EnumCalendarPos.bot}
              onChange={onChangeFromDate}
            />
          </div>
          <div className="debit-titlle">Đến ngày</div>
          <div className="debit-input-container">
            <div className="debit-date">{toDate}</div>
            <Calendar
              align={EnumCalendarAlign.right}
              pos={EnumCalendarPos.bot}
              onChange={onChangeToDate}
            />
          </div>
          <div className="debit-titlle">Chọn nhanh</div>
          <div className="debit-slide-from-date btn">
            <div
              className="debit-radio-btn"
              onClick={() => {
                setDateType(EnumDateType.Tuan);
                onChangeDateType(EnumDateType.Tuan);
              }}
            >
              <input type="radio" checked={dateType == EnumDateType.Tuan} />
              <span style={{ marginLeft: 5 }}>Tuần</span>
            </div>
            <div
              className="debit-radio-btn"
              onClick={() => {
                setDateType(EnumDateType.Thang);
                onChangeDateType(EnumDateType.Thang);
              }}
            >
              <input type="radio" checked={dateType == EnumDateType.Thang} />
              <span style={{ marginLeft: 5 }}>Tháng</span>
            </div>
            <div
              className="debit-radio-btn"
              onClick={() => {
                setDateType(EnumDateType.Quy);
                onChangeDateType(EnumDateType.Quy);
              }}
            >
              <input type="radio" checked={dateType == EnumDateType.Quy} />
              <span style={{ marginLeft: 5 }}>Quý</span>
            </div>
            <div
              className="debit-radio-btn"
              onClick={() => {
                setDateType(EnumDateType.Nam);
                onChangeDateType(EnumDateType.Nam);
              }}
            >
              <input type="radio" checked={dateType == EnumDateType.Nam} />
              <span style={{ marginLeft: 5 }}>Năm</span>
            </div>
          </div>
          <div className="debit-slide-from-date">
            {dateType == EnumDateType.Thang ? (
              <div className="debit-select-component">
                <span style={{ marginRight: 10 }}>Tháng</span>
                <select
                  value={month}
                  onChange={(event) => {
                    setMonth(event.target.value);
                    onChangeToMonthType(Number(event.target.value), year);
                  }}
                >
                  {elmThang.length > 0 ? elmThang : null}
                </select>
              </div>
            ) : dateType == EnumDateType.Quy ? (
              <div className="debit-select-component">
                <span style={{ marginRight: 10 }}>Quý</span>
                <select
                  value={quarter}
                  onChange={(event) => {
                    setQuarter(event.target.value);
                    onChangeToQuarterType(Number(event.target.value), year);
                  }}
                >
                  {elmQuy.length > 0 ? elmQuy : null}
                </select>
              </div>
            ) : dateType == EnumDateType.Tuan ? (
              <div className="debit-select-component">
                <span style={{ marginRight: 10 }}>Tuần</span>
                <select
                  value={week}
                  onChange={(event) => {
                    setWeek(event.target.value);
                    onChangeToWeekType(Number(event.target.value), year);
                  }}
                >
                  {elmTuan.length > 0 ? elmTuan : null}
                </select>
              </div>
            ) : null}
            <div className="debit-select-component">
              <span style={{ marginRight: 10 }}>Năm</span>
              <select
                value={year}
                onChange={(event) => {
                  setYear(event.target.value);
                  if (dateType == EnumDateType.Tuan) {
                    onChangeToWeekType(week, Number(event.target.value));
                  } else if (dateType == EnumDateType.Thang) {
                    onChangeToMonthType(month, Number(event.target.value));
                  } else if (dateType == EnumDateType.Quy) {
                    onChangeToQuarterType(quarter, Number(event.target.value));
                  } else if (dateType == EnumDateType.Nam) {
                    onChantoYearType(Number(event.target.value));
                  }
                }}
              >
                {elmNam.length > 0 ? elmNam : null}
              </select>
            </div>
          </div>
          {keywordInput()}
          <div className="debit-btn" onClick={onShowDebit}>
            Xem
          </div>
        </div>
        <div className="debit-detail">
          <div className="account-detail-title">Chi tiết công nợ</div>
          <div className="debit-detail-wrapper">
            {moneyAmount >= 0 ? (
              <div className="debit-detail-component">
                Số dư tài khoản: <span style={{ color: 'blue' }}>{moneyAmount}¥</span>
              </div>
            ) : (
              <div className="debit-detail-component">
                Số tiền chưa thanh toán: <span style={{ color: 'red' }}>{moneyAmount}¥</span>
              </div>
            )}
          </div>
          <table className="account-debit-table">
            <tr>
              <th>STT</th>
              <th>Ngày</th>
              <th>Tiêu đề</th>
              <th>Số tiền</th>
              <th>Nội dung</th>
            </tr>
            {accountBalanceHistoryList.length > 0
              ? accountBalanceHistoryList.map((value, index) => {
                  return (
                    <tr className={`${index % 2 == 1 ? 'le' : 'chan'}`}>
                      <td>{index + 1}</td>
                      <td>{value.createdAt}</td>
                      <td>{value.accountActionTitle}</td>
                      {value.coefficient == -1 ? (
                        <td style={{ color: 'red' }}>-{value.moneyAmount}¥</td>
                      ) : (
                        <td style={{ color: 'blue' }}>{value.moneyAmount}¥</td>
                      )}
                      <td>{value.note}</td>
                    </tr>
                  );
                })
              : null}
          </table>
        </div>
      </div>
    </div>
  );
};

export default AccountBalance;
