import React, { useState, useEffect } from 'react';
import './AddOrderScreen.css';
import {
  useGetIdProduct,
  useGetProduct,
  useGetRealm,
  usePostAgencySimOrder,
} from 'src/api/backend-api';
import {
  PageData,
  ProductType,
  SimOrderDetailsType,
  SimOrderType,
  SimPriceType,
} from 'src/api/models';
import OrderItem from 'src/components/OrderItem/OrderItem';
import { useAddPopup } from 'src/state/application/hooks';
import { useHistory } from 'react-router';
import { useGetMySimPrice } from 'src/api/simPriceApi';
import { useGetAddressByZipcode } from 'src/api/commonInfomationApi';

enum EnumPopupState {
  add = 1,
  delete = 2,
}

const AddOrderScreen: React.FC = () => {
  //Value
  const history = useHistory();

  //State
  const [realmList, setRealmList] = useState<number[]>(null);
  const [fullName, setFullName] = useState(undefined);
  const [fullNameError, setFullNameError] = useState(null);

  const [phoneNumber, setPhoneNumber] = useState(undefined);

  const [zipCode, setZipCode] = useState(undefined);
  const [zipCodeError, setZipCodeError] = useState(null);

  const [address1, setAddress1] = useState(undefined);
  const [address2, setAddress2] = useState(undefined);
  const [address3, setAddress3] = useState(undefined);
  const [address4, setAddress4] = useState(undefined);
  const [addressError, setAddressError] = useState(null);

  const [note, setNote] = useState(undefined);
  const [simList, setSimList] = useState<PageData<ProductType>>(null);
  const [orderList, setOrderList] = useState<SimOrderDetailsType[]>([]);

  const [focusInput, setFocusInput] = useState(null);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [count, setCount] = useState(1);
  const [productIdFocus, setProductIdFocus] = useState(null);
  const [productNameFocus, setProductNameFocus] = useState(null);
  const [productData, setProductData] = useState(null);
  const [productNameDelete, setProductNameDelete] = useState(null);

  const [popupState, setPopupState] = useState<EnumPopupState>(EnumPopupState.delete);
  const [deleteIndex, setDeleteIndex] = useState(null);

  const [simPriceList, setSimPriceList] = useState<SimPriceType[]>(null);
  //End of state

  //Function
  const getRealm = useGetRealm();
  const getProduct = useGetProduct();
  const getProductById = useGetIdProduct();
  const postSimOrder = usePostAgencySimOrder();
  const addPopup = useAddPopup();
  const getSimPrice = useGetMySimPrice();
  const getAddressByZipcode = useGetAddressByZipcode();

  const onDelete = (index: number) => {
    const _orderList = orderList.filter(() => true);
    _orderList.splice(index, 1);
    setOrderList(_orderList);
  };

  const onShowDeletePopup = (index: number) => {
    const _orderList = orderList.filter(() => true);
    setProductNameDelete(_orderList[index].product.productName);
    setPopupState(EnumPopupState.delete);
    setIsShowPopup(true);
    setDeleteIndex(index);
  };

  const onParseZipcode = (_zipcode: string) => {
    getAddressByZipcode(_zipcode)
      .then((data) => {
        if (data?.results) {
          setAddress1(data.results[0].address1);
          setAddress2(data.results[0].address2);
          setAddress3(data.results[0].address3);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Validate
  const validateFullName = () => {
    let isContinue = true;

    if (!fullName || fullName == '') {
      isContinue = false;
      setFullNameError('Chưa nhập tên');
    } else setFullNameError(null);

    return isContinue;
  };

  const validateZipCode = () => {
    let isContinue = true;

    if (!zipCode || zipCode == '') {
      isContinue = false;
      setZipCodeError('Chưa nhập mã bưu điện');
    } else setZipCodeError(null);

    return isContinue;
  };

  const validateAddress = () => {
    let isContinue = true;

    if (
      (!address1 || address1 == '') &&
      (!address2 || address2 == '') &&
      (!address3 || address3 == '') &&
      (!address4 || address4 == '')
    ) {
      isContinue = false;
      setAddressError('Chưa nhập địa chỉ');
    } else setAddressError(null);

    return isContinue;
  };

  const addOrderItem = () => {
    const list = orderList.filter(() => true);

    for (let i = 1; i <= count; i++) {
      const _item: SimOrderDetailsType = {
        productId: productIdFocus,
        quantity: 1,
        product: productData,
      };
      list.push(_item);
    }
    setOrderList(list);
  };

  const onPostSimOrder = () => {
    const isFullName = validateFullName();
    const isZipCode = validateZipCode();
    const isAddress = validateAddress();

    if (isFullName && isZipCode && isAddress) {
      const _orderList = orderList;
      const _arr: SimOrderDetailsType[] = [];

      _orderList.map((value) => {
        const _temp: SimOrderDetailsType = {
          productId: value.productId,
        };
        _arr.push(_temp);
      });

      if (_arr.length > 0) {
        const simOrder: SimOrderType = {
          fullname: fullName,
          phoneNumber: phoneNumber,
          zipCode: zipCode,
          address1: address1,
          address2: address2,
          address3: address3,
          address4: address4,
          simOrderDetails: _arr,
        };
        postSimOrder(simOrder)
          .then((data) => {
            history.push(`/ordered-list?simOrderId=${data.simOrderId}`);
            addPopup({
              txn: {
                success: true,
                summary: 'Lên đơn thành công!',
              },
            });
          })
          .catch((error) => {
            addPopup({
              error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
            });
          });
      } else {
        addPopup({
          error: { message: 'Danh sách sim không được để trống', title: 'Đã có lỗi xảy ra!' },
        });
      }
    } else {
      addPopup({
        error: { message: 'Chưa nhập đủ thông tin', title: 'Đã có lỗi xảy ra!' },
      });
    }
  };

  const handlePressInput = (event) => {
    const form = event.target.form;
    const index = [...form].indexOf(event.target);

    if (event.key.toLowerCase() === 'enter') {
      if (focusInput >= 1 && focusInput <= 7) {
        form.elements[index + 1].focus();
        setFocusInput(focusInput + 1);
        event.preventDefault();
      } else {
        onPostSimOrder();
      }
    } else if (event.key.toLowerCase() === 'arrowdown') {
      if (focusInput >= 1 && focusInput <= 7) {
        form.elements[index + 1].focus();
        setFocusInput(focusInput + 1);
        event.preventDefault();
      }
    } else if (event.key.toLowerCase() === 'arrowup') {
      if (focusInput >= 2 && focusInput <= 8) {
        setFocusInput(focusInput - 1);
        form.elements[index - 1].focus();
        event.preventDefault();
      }
    }
  };

  //Component
  //1
  const fullNameInput = () => {
    return (
      <div className="add-order-input-wrapper">
        <div
          className={`add-order-input ${focusInput == 1 ? 'focus-input' : ''} ${
            fullName ? 'validate-input' : ''
          }`}
        >
          <div className="add-order-input-title">Tên khách hàng không dấu</div>
          <input
            type="text"
            value={fullName}
            onChange={(event) => {
              setFullName(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(1);
            }}
            onBlur={() => {
              validateFullName();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 1}
            onKeyDown={handlePressInput}
            placeholder={'VD: NGUYEN VAN A'}
          />
        </div>
        <div style={{ color: 'red', paddingLeft: 2 }}>{fullNameError}</div>
      </div>
    );
  };

  //2
  const phoneNumberInput = () => {
    return (
      <div className="add-order-input-wrapper">
        <div
          className={`add-order-input ${focusInput == 2 ? 'focus-input' : ''} ${
            phoneNumber ? 'validate-input' : ''
          }`}
        >
          <div className="add-order-input-title">Số điện thoại</div>
          <input
            type="text"
            value={phoneNumber}
            onChange={(event) => {
              setPhoneNumber(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(2);
            }}
            onBlur={() => {
              setFocusInput(null);
            }}
            autoFocus={focusInput == 2}
            onKeyDown={handlePressInput}
            placeholder={'Không bắt buộc'}
          />
        </div>
      </div>
    );
  };

  //3
  const zipCodeInput = () => {
    return (
      <div className="add-order-input-wrapper">
        <div
          className={`add-order-input ${focusInput == 3 ? 'focus-input' : ''} ${
            zipCode ? 'validate-input' : ''
          }`}
        >
          <div className="add-order-input-title">Mã bưu điện</div>
          <input
            type="text"
            value={zipCode}
            onChange={(event) => {
              onParseZipcode(event.target.value);
              setZipCode(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(3);
            }}
            onBlur={() => {
              validateZipCode();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 3}
            onKeyDown={handlePressInput}
            placeholder="VD: 123-456"
          />
        </div>
        <div style={{ color: 'red', paddingLeft: 2 }}>{zipCodeError}</div>
      </div>
    );
  };

  //4
  const address1Input = () => {
    return (
      <div className="add-order-input-wrapper">
        <div
          className={`add-order-input ${focusInput == 4 ? 'focus-input' : ''} ${
            address1 ? 'validate-input' : ''
          }`}
        >
          <div className="add-order-input-title">Địa chỉ dòng 1</div>
          <input
            type="text"
            value={address1}
            onChange={(event) => {
              setAddress1(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(4);
            }}
            onBlur={() => {
              validateAddress();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 4}
            onKeyDown={handlePressInput}
            placeholder="Điền lần lượt 1->2->3->4"
          />
        </div>
      </div>
    );
  };

  //5
  const address2Input = () => {
    return (
      <div className="add-order-input-wrapper">
        <div
          className={`add-order-input ${focusInput == 5 ? 'focus-input' : ''} ${
            address2 ? 'validate-input' : ''
          }`}
        >
          <div className="add-order-input-title">Địa chỉ dòng 2</div>
          <input
            type="text"
            value={address2}
            onChange={(event) => {
              setAddress2(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(5);
            }}
            onBlur={() => {
              validateAddress();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 3}
            onKeyDown={handlePressInput}
            placeholder="Điền lần lượt 1->2->3->4"
          />
        </div>
      </div>
    );
  };

  //6
  const address3Input = () => {
    return (
      <div className="add-order-input-wrapper">
        <div
          className={`add-order-input ${focusInput == 6 ? 'focus-input' : ''} ${
            address3 ? 'validate-input' : ''
          }`}
        >
          <div className="add-order-input-title">Địa chỉ dòng 3</div>
          <input
            type="text"
            value={address3}
            onChange={(event) => {
              setAddress3(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(6);
            }}
            onBlur={() => {
              validateAddress();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 6}
            onKeyDown={handlePressInput}
            placeholder="Điền lần lượt 1->2->3->4"
          />
        </div>
      </div>
    );
  };

  //7
  const address4Input = () => {
    return (
      <div className="add-order-input-wrapper">
        <div
          className={`add-order-input ${focusInput == 7 ? 'focus-input' : ''} ${
            address4 ? 'validate-input' : ''
          }`}
        >
          <div className="add-order-input-title">Địa chỉ dòng 4</div>
          <input
            type="text"
            value={address4}
            onChange={(event) => {
              setAddress4(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(7);
            }}
            onBlur={() => {
              validateAddress();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 7}
            onKeyDown={handlePressInput}
            placeholder="Điền lần lượt 1->2->3->4"
          />
        </div>
        <div style={{ color: 'red', paddingLeft: 2 }}>{addressError}</div>
      </div>
    );
  };

  //8
  const noteInput = () => {
    return (
      <div className="add-order-input-wrapper">
        <div
          className={`add-order-input ${focusInput == 8 ? 'focus-input' : ''} ${
            note ? 'validate-input' : ''
          }`}
        >
          <div className="add-order-input-title">Ghi chú</div>
          <input
            type="text"
            value={note}
            onChange={(event) => {
              setNote(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(8);
            }}
            onBlur={() => {
              setFocusInput(null);
            }}
            autoFocus={focusInput == 8}
            onKeyDown={handlePressInput}
            placeholder="VD: Hình thức gửi mong muốn"
          />
        </div>
      </div>
    );
  };

  const popup = () => {
    return (
      <div className={`add-order-poopup-container ${isShowPopup ? 'show' : ''}`}>
        <div className="add-order-popup-wrapper">
          <div className="add-order-popup-detail">
            <div>Bạn có chắc muốn {popupState == EnumPopupState.add ? 'thêm' : 'xóa'}</div>
            <div>
              {popupState == EnumPopupState.add ? count : 1} x{' '}
              {popupState == EnumPopupState.add ? productNameFocus : productNameDelete}
            </div>
          </div>
          <div className="add-order-popup-btn-container">
            <div
              onClick={() => {
                setIsShowPopup(false);
                if (popupState == EnumPopupState.add) {
                  addOrderItem();
                } else {
                  onDelete(deleteIndex);
                }
              }}
            >
              {popupState == EnumPopupState.add ? 'Thêm' : 'Xóa'}
            </div>
            <div
              onClick={() => {
                setIsShowPopup(false);
              }}
            >
              Hủy
            </div>
          </div>
        </div>
      </div>
    );
  };
  //End of component

  //useEffect
  useEffect(() => {
    getRealm()
      .then((data) => {
        const list: number[] = [];
        data.map((value) => {
          if (value?.isHidden === true) {
            list.push(value.productRealmId);
          }
        });

        setRealmList(list);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getRealm]);

  useEffect(() => {
    if (realmList) {
      getProduct(null, 1, 9999, realmList, [], false)
        .then((data) => {
          setSimList(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [getProduct, realmList]);

  useEffect(() => {
    if (productIdFocus) {
      getProductById(productIdFocus)
        .then((data) => {
          setProductNameFocus(data.productName);
          setProductData(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [getProductById, productIdFocus]);

  useEffect(() => {
    const date = new Date();
    const dd = date.getDate();
    const mm = date.getMonth() + 1;
    const yy = date.getFullYear();

    const temp = `${dd}/${mm}/${yy}`;

    getSimPrice(temp)
      .then((data) => {
        setSimPriceList(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getSimPrice]);

  //Main
  return (
    <div className="add-order-container">
      <div className="add-order-form">
        <div className="add-order-title">THÔNG TIN NGƯỜI NHẬN</div>
        <form>
          {fullNameInput()}
          {phoneNumberInput()}
          {zipCodeInput()}
          {address1Input()}
          {address2Input()}
          {address3Input()}
          {address4Input()}
          {noteInput()}
        </form>
      </div>
      <div className="add-order-detail-container">
        <div className="add-order-title">DANH SÁCH SIM</div>
        {orderList.map((value, index) => {
          return (
            <OrderItem
              data={value.product}
              onDelete={onShowDeletePopup}
              index={index}
              priceList={simPriceList}
            />
          );
        })}
        <div className="add-order-btn-container">
          <select
            value={productIdFocus ? productIdFocus : 'default'}
            className="add-order-option"
            onChange={(event) => {
              setProductIdFocus(Number(event.target.value));
              setCount(1);
              event.preventDefault();
            }}
          >
            <option value={'default'}>Chọn sim</option>
            {simList
              ? simList.items.map((value) => {
                  return <option value={value.productId}>{value.productName}</option>;
                })
              : null}
          </select>
          <div className="add-order-quantity-container">
            <div
              className="add-order-quantity-btn minus"
              onClick={() => {
                if (count > 1) {
                  setCount(count - 1);
                }
              }}
            >
              <i className="fas fa-minus"></i>
            </div>
            <input
              className="add-order-quantity"
              type="number"
              value={count}
              onChange={(e) => {
                if (Number(e.target.value)) {
                  setCount(Number(e.target.value));
                } else {
                  setCount(null);
                }
              }}
            />
            <div
              className="add-order-quantity-btn plus"
              onClick={() => {
                setCount(count + 1);
              }}
            >
              <i className="fas fa-plus"></i>
            </div>
          </div>
          <button
            className="add-order-btn-wrapper"
            onClick={(event) => {
              if (productIdFocus) {
                if (count && count > 0) {
                  setPopupState(EnumPopupState.add);
                  setIsShowPopup(true);
                } else {
                  addPopup({
                    txn: {
                      success: false,
                      summary: 'Số lượng không hợp lệ',
                    },
                  });
                }
              } else {
                addPopup({
                  txn: {
                    success: false,
                    summary: 'Chưa chọn sim',
                  },
                });
              }
              event.preventDefault();
            }}
          >
            Thêm
          </button>
        </div>
        <div
          className="add-order-btn btn2"
          onClick={() => {
            onPostSimOrder();
          }}
        ></div>
      </div>
      {popup()}
    </div>
  );
};

export default AddOrderScreen;
