import { useState } from 'react';
import React from 'react';
import './GuidePageDetail.css';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useGetGuidePageByKey } from 'src/api/guidePageApi';

const GuidePageDetail: React.FC = () => {
  const params = useParams<{ guidePageKey: string }>();
  const guidePageKey = params.guidePageKey;

  //State
  const [value, setValue] = useState(null);
  const [title, setTitle] = useState(null);

  //Function
  const getGuidePageByKey = useGetGuidePageByKey();

  useEffect(() => {
    if (guidePageKey) {
      getGuidePageByKey(guidePageKey)
        .then((data) => {
          setValue(data.guidePageValue);
          setTitle(data.guidePageTitle);
        })
        .catch((error) => {
          console.log('guide page detail error');
          console.log(error);
        });
    }
  }, [getGuidePageByKey, guidePageKey]);

  return value ? (
    <div className="p-2">
      <div className="ordered-list-title">{title}</div>
      <div
        className="p-0 m-1"
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      />
    </div>
  ) : null;
};

export default GuidePageDetail;
