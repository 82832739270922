import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  EnumDisplayConfig,
  EnumNotificationStatus,
  EnumNotiTemplateKey,
  NotificationType,
} from 'src/api/models';
import {
  useGetNotification,
  useReadAllNotification,
  useReadNotificationById,
} from 'src/api/notificationApi';
import { useSetNotiCount } from 'src/state/application/hooks';
import InfiniteScroll from 'react-infinite-scroll-component';
import './DashBoardHeaderBar.css';

type NoteNotiType = {
  functionId: EnumDisplayConfig;
  objectId: number;
};

interface IDBHB {
  page: number;
  onChangePage: (...args: any[]) => any;
  onHiddenPopup: (...args: any[]) => any;
}

const DashBoardHeaderBar: React.FC<IDBHB> = (props) => {
  //Value
  const history = useHistory();
  const SIZE = 10;
  const page = props.page;

  //State
  const [notiList, setNotiList] = useState<NotificationType[]>([]);
  const [notiMaxCount, setNotiMaxCount] = useState(null);
  const [loadNotiFlag, setLoadNotiFlag] = useState(false);
  const [p, setP] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  //Function
  const setNotiCount = useSetNotiCount();
  const getNoti = useGetNotification();
  const readAll = useReadAllNotification();
  const readNotificationById = useReadNotificationById();
  const onReadById = (notificationId: number) => {
    readNotificationById(notificationId)
      .then(() => {
        setLoadNotiFlag(!loadNotiFlag);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onReadAll = () => {
    readAll();
  };

  const onCheck = (
    notificationTemplateKey: EnumNotiTemplateKey,
    notificationData: string,
    notificationId: number,
  ) => {
    if (notificationTemplateKey == EnumNotiTemplateKey.NOTE) {
      const temp: NoteNotiType = JSON.parse(notificationData);
      if (temp.functionId == EnumDisplayConfig.Sim_order) {
        onReadById(notificationId);
        const url = `/ordered-list/${temp.objectId}`;
        history.push(url);
      } else if (temp.functionId == EnumDisplayConfig.Sim_da_ban) {
        onReadById(notificationId);
        const url = `/active-sim-list/${temp.objectId}`;
        history.push(url);
      }
    } else if (notificationTemplateKey == EnumNotiTemplateKey.REPORT_SIM_ERROR) {
      onReadById(notificationId);
      const temp = JSON.parse(notificationData);
      const url = `/active-sim-list/${temp.simId}`;
      history.push(url);
    } else if (notificationTemplateKey == EnumNotiTemplateKey.STOP_SERVICE) {
      onReadById(notificationId);
      const temp = JSON.parse(notificationData);
      const url = `/active-sim-list/${temp.simId}`;
      history.push(url);
    }
  };

  const fetchData = () => {
    getNoti(SIZE, p)
      .then((data) => {
        const temp = notiList.filter(() => true);
        const d = data.data.items;
        const arr = temp.concat(d);
        setNotiCount(data.badge);
        setNotiList(arr);
        if (arr.length == notiMaxCount) {
          setHasMore(false);
        } else {
          setHasMore(true);
          setP(p + 1);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getNoti(SIZE, 1)
      .then((data) => {
        setNotiCount(data.badge);
        setNotiList(data.data.items);
        setNotiMaxCount(data.data.count);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [getNoti, loadNotiFlag, page, setNotiCount]);

  //Main
  return (
    <div className={`noti-web-con show`} id="noti-popup">
      <div className="noti-tick-all-container">
        <div
          className="noti-tick-all"
          onClick={() => {
            onReadAll();
          }}
        >
          <a href="">
            <i>Đánh dấu tất cả đã đọc</i>
          </a>
        </div>
      </div>
      <div className="infinite-scr-con" id="scrollableDiv">
        <InfiniteScroll
          dataLength={notiList.length} //This is important field to render the next data
          next={fetchData}
          hasMore={hasMore}
          style={{ display: 'flex', flexDirection: 'column' }} //To put endMess
          scrollableTarget="scrollableDiv"
          loader={<h4>Hết</h4>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          {notiList.map((value) => {
            return (
              <div
                className={`noti-component ${
                  value.status == EnumNotificationStatus.Read ? 'read' : ''
                }`}
              >
                <div className="noti-avt">
                  {value.avataUrl ? (
                    <img src={value.avataUrl} alt="avt" />
                  ) : (
                    <div className="noti-avt-null">avt</div>
                  )}
                </div>
                <div className="noti-value">
                  <div className="noti-title">
                    <div>{value.notificationTitle}</div>
                    <div style={{ fontSize: 12 }}>{value.createdAt}</div>
                  </div>
                  <div className="noti-main">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: value.notificationValue,
                      }}
                      onClick={() => {
                        onCheck(
                          value.notificationTemplateKey,
                          value.notificationData,
                          value.notificationId,
                        );
                        props.onHiddenPopup();
                      }}
                    />
                    <div
                      className={`noti-tick ${
                        value.status != EnumNotificationStatus.Read ? 'blue' : ''
                      }`}
                      onClick={() => {
                        if (value.status != EnumNotificationStatus.Read) {
                          onReadById(value.notificationId);
                        }
                      }}
                    >
                      <i className="fas fa-eye"></i>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </InfiniteScroll>
      </div>
    </div>
  );
};
export default DashBoardHeaderBar;
