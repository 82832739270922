import React, { useState, useEffect } from 'react';
import { GuidePageType } from 'src/api/models';
import { useHistory } from 'react-router';
import './GuidePage.css';
import { useGetGuidePage } from 'src/api/guidePageApi';

const GuidePage: React.FC = () => {
  //Value
  const history = useHistory();

  //State
  const [guidePageList, setGuidePageList] = useState<GuidePageType[]>(null);

  //Function
  const getGuidePage = useGetGuidePage();

  //useEffect
  useEffect(() => {
    getGuidePage()
      .then((data) => {
        setGuidePageList(data);
      })
      .catch((error) => {
        console.log('guide page error');
        console.log(error);
      });
  }, [getGuidePage]);
  //End of useEffect

  //Component
  const list = () => {
    return (
      <div className="row p-0 m-0 justify-content-center">
        <div className="col-12 p-1 m-0 ordered-list-wrapper">
          <div className="ordered-list-title">HƯỚNG DẪN</div>
          <div className="guide-page-main">
            {guidePageList
              ? guidePageList.map((value) => {
                  return (
                    <div
                      className="guide-page-cpm"
                      onClick={() => {
                        const url = `guide-page/${value.guidePageKey}`;
                        history.push(url);
                      }}
                    >
                      <div className="guide-page-avt">
                        {value?.guideAvatar ? (
                          <img src={value.guideAvatar} alt="avt" />
                        ) : (
                          <div className="guide-page-no-avt">no-avt</div>
                        )}
                      </div>
                      <div className="guide-page-text">
                        <div className="guide-page-title">{value.guidePageTitle}</div>
                        <div className="guide-page-des">
                          <i>{value.description}</i>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    );
  };

  //Main
  return <div className="container-fluid ordered-list-container p-0 m-0 ">{list()}</div>;
};

export default GuidePage;
